module.exports = {
  siteName: 'AntD Admin',
  logoPath: '/logo.svg',
  apiPrefix: '/api/v1',
  fixedHeader: true, // sticky primary layout header
  apiUrl: process.env.REACT_APP_API_URL,
  // || 'https://api-zenata.pixair.ma'
  /* Layout configuration, specify which layout to use for route. */
  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/],
    },
  ],
  cesiumToken: process.env.REACT_APP_ION_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmMzQyYjM1Ny1kM2IwLTQ0ZWYtYjVkZi0xYzUyYWQ3YTgyNzAiLCJpZCI6MjgyMjUsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciLCJnYyIsInByIl0sImlhdCI6MTU5MzczODM4M30.6YSWPeDKswshpJ0zwz-hQeLwDKjftJ--YAXEnDYbJnk'
}