import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  message,
  Alert
} from "antd";
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { inject, observer } from "mobx-react";

import { SignInWrapper, AlertBanner } from "./style";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

const { Text, Title } = Typography;
const { Content } = Layout;

const AuthenticationPage = ({ authStore, userStore }) => {
  const history = useNavigate();
  const { authPage } = useParams();
  const [form] = Form.useForm();
  const [signinPassword, setSigninPassword] = useState("");

  const SIGN_IN = "sign-in";
  const SIGN_UP = "sign-up";
  const CONFIRM_PASSWORD = "confirm-password";
  const RESET_PASSWORD = "reset-password";
  const CONFIRM_ACCOUNT = "confirm-account";
  const CHANGE_PASSWORD = "change-password";

  const [passwordInput, setPasswordInput] = useState("");
  const [displayButton, setDisplayButton] = useState(false);
  const [displayAlertPassword, setDisplayAlertPassword] = useState(false);

  const [lengthError, setLengthError] = useState(false);
  const [lowercaseError, setLowercaseError] = useState(false);
  const [noSpaceError, setNoSpaceError] = useState(false);
  const [noNumberError, setNoNumberError] = useState(false);

  const NO_SPACE_ERROR = "Your password must not contain any spaces";
  const LENGTH_ERROR = "Your password must contain at least 8 characters";
  const LOWERCASE_ERROR = "Your password must contain at least one lowercase character";
  const NO_NUMBER_ERROR = "Your password must contain at least one numberic character";

  useEffect(() => {
    if (authPage === SIGN_UP || authPage === CONFIRM_PASSWORD || authPage === RESET_PASSWORD || authPage === CONFIRM_PASSWORD) {
      form.resetFields();
    }
    if (authPage === SIGN_UP) {
      setDisplayAlertPassword(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPage])

  const renderTitle = (pageParams, mainTitle, subTitle) => {
    if (pageParams === SIGN_IN) {
      mainTitle = "Welcome Back!";
      subTitle = "Sign in to continue to Zenata pixland.";
    }
    else if (pageParams === SIGN_UP) {
      mainTitle = "Register Account";
      subTitle = "Get your free account now.";
    }
    else if (pageParams === CONFIRM_PASSWORD) {
      mainTitle = "Confirm Password";
      subTitle = "You can change new password here.";
    }
    else if (pageParams === CONFIRM_ACCOUNT) {
      mainTitle = "Confirm Account";
      subTitle = "We have send a code to your email. Enter it below to confirm your account.";
    }
    else if (pageParams === RESET_PASSWORD) {
      mainTitle = "Reset Password";
      subTitle = "You can reset your password here.";
    }
    else if (pageParams === CHANGE_PASSWORD) {
      mainTitle = "Change Password";
      subTitle = "You can change your password here.";
    }
    return (
      <>
        <Title
          className="main-title"
          level={3}
        >
          {mainTitle}
        </Title>
        <Title
          className="sub-title">
          {subTitle}
        </Title>
      </>
    )
  }

  const signIn = async (values, history) => {
    await authStore.userLogin(values.email, values.password, values.remember, history)
      .then(response => {
        userStore.setCurrentUser(response?.data);
        if(response?.isFirstLogin ){
          message.warning("Please change your password to access system");
          history('/auth/change-password');
        }else{
          message.success(`Welcome ${response.data?.username} !`);
           history('/home');
        }
      })
  }

  const signUp = async (values, history) => {
    if (!values.remember) {
      message.error('Please read and accept terms and conditions.')
      return
    }
    await authStore.userRegister(values, history).then(response => {
      message.success('Please sign in to access system.');
      form.resetFields();
      // history('/auth/sign-in')
    })
  }

  const confirmPassword = async (values, history) => {
    await authStore.userResetPassword(values, history)
      .then(response => {
        message.success('Reset password successfully. Please sign in to access system.');
        history('/auth/sign-in');
        form.resetFields();
      })
  }

  const resetPassword = async (values, history) => {
    await authStore.userForgotPassword(values, history)
      .then(response => {
        message.success('Instructions to reset your password have been sent to your email.');
        form.resetFields();
      })
  }

  const confirmAccount = async (values, history) => {
    await authStore.userConfirmAccount(values, history)
      .then(response => {
        message.success('Confirm your account successfully. Please sign in to access our services!');
        history('/auth/sign-in');
        form.resetFields();
      })
      .catch(err => {
        message.error('Something went wrong. Please try again!');
      })
  }

  const changePassWord = async  (values, history) => {
    const objectInfo = {
      email : values?.email,
      currentPassword : values?.currentPassword,
      newPassword : values?.newPassword,
    }
    await authStore.userChangePassword(objectInfo, history)
      .then(response => {
        message.success('Change your password successfully. Please sign in to access our services!');
        history('/auth/sign-in');
        form.resetFields();
      })
      .catch(err => {
        // message.error('Something went wrong. Please try again!');
      })
  }

  const onFinishFailed = (errorInfo) => { };

  const validateEmail = (rule, value, callback) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
    if (value && !emailRegex.test(value)) {
      callback("Invalid email");
    } else {
      callback();
    }
  };

  const comparePassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Password does not match.');
    } else {
      callback();
    }
  }

  const changeSignInPassword = (e) => {
    setSigninPassword(e.target.value);
  }

  const changeSignUpPassword = (e) => {
    let password = e.target.value;
    if (password === "") {
      setPasswordInput("");
      setDisplayAlertPassword(false);
      setDisplayButton(false);
      return false;
    } else {
      setPasswordInput(password);
      setDisplayAlertPassword(true);
    }

    let validPassword = validatePassword(password);
    if (validPassword) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
      return false;
    }
  }

  const validatePassword = (value) => {
    const checkLowerCaseRegex = /[a-z]/g;
    const checkNumberRegex = /[0-9]/g;
    const checkNonSpacingRegex = /^\S*$/;

    // No space
    if (value && !value.match(checkNonSpacingRegex)) {
      setNoSpaceError(false);
    } else {
      setNoSpaceError(true);
    }
    // Length >= 8
    if (value && (value.length < 8)) {
      setLengthError(false);
    } else {
      setLengthError(true);
    }
    // At least one lowercase
    if (value && !value.match(checkLowerCaseRegex)) {
      setLowercaseError(false);
    } else {
      setLowercaseError(true);
    }
    // At least one number
    if (value && !value.match(checkNumberRegex)) {
      setNoNumberError(false);
    } else {
      setNoNumberError(true);
    }
    return true;
  }

  const renderContent = (pageParams) => {
    const onFinish = async (values) => {
      if (authPage === SIGN_IN) {
        signIn(values, history);
      }
      if (authPage === SIGN_UP) {
        signUp(values, history);
      }
      if (authPage === CONFIRM_PASSWORD) {
        confirmPassword(values, history);
      }
      if (authPage === RESET_PASSWORD) {
        resetPassword(values, history);
      }
      if (authPage === CONFIRM_ACCOUNT) {
        confirmAccount(values, history);
      }
      if (authPage === CHANGE_PASSWORD) {
        changePassWord(values, history);
      }
    }

    const signInForm =
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please fill in your email!",
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input className="sign-input" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please fill in your password!",
            },
          ]}
        >
          <Input.Password
            value={signinPassword}
            onChange={changeSignInPassword}
            className="sign-input"
            placeholder="Enter password" />
        </Form.Item>
        <Row
          justify="space-between"
          className="row-center">
          <Form.Item
            name="remember"
            className="align-center"
            valuePropName="checked"
            initialValue={true}
          >
            <Checkbox>{"Remember me"}</Checkbox>
          </Form.Item>
          <Link
            to="/auth/reset-password"
            className="forgot-password"
            style={{ marginTop: "5px" }}
          >
            Forgot password?
          </Link>
        </Row>
        <Row justify='center'>
          <Col span={24}>
            <Form.Item>
              <Button
                className="btn-sign-in"
                type="primary"
                htmlType="submit"
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    const signUpForm =
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username"
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please fill in your username!",
            },
          ]}
        >
          <Input className="sign-input" placeholder="Enter username" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please fill in your email!",
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input className="sign-input" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please fill in your password!",
            },
          ]}
        >
          <Input.Password
            value={passwordInput}
            onChange={changeSignUpPassword}
            className="sign-input"
            placeholder="Enter password"
          />
        </Form.Item>
        <Form.Item
          className="username"
          label="Confirm password"
          name="rePassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            {
              validator: comparePassword
            }
          ]}
        >
          <Input.Password className="sign-input" placeholder="Confirm password" />
        </Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          style={{ marginBottom: "10px" }}
          rules={[
            {
              required: true,
              message: "Please accept our Terms and Conditions!"
            }
          ]}>
          <Checkbox>
            I accept the{" "}
            <a href="#terms">
              Terms and Conditions
            </a>
          </Checkbox>
        </Form.Item>
        <Row justify="center" style={{ marginBottom: "15px" }}>
          <Col span={24}>
            {
              passwordInput && displayAlertPassword ?
                <>
                  {
                    noSpaceError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {NO_SPACE_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {NO_SPACE_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    lengthError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {LENGTH_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {LENGTH_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    lowercaseError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {LOWERCASE_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {LOWERCASE_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    noNumberError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {NO_NUMBER_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {NO_NUMBER_ERROR}
                        </Text>
                      </>
                  }
                </>
                :
                null
            }
          </Col>
        </Row>
        <Row justify='center'>
          <Col span={24}>
            {
              lengthError && lowercaseError && noSpaceError && noNumberError && displayButton ?
                <Form.Item>
                  <Button
                    className="btn-sign-in"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    REGISTER
                  </Button>
                </Form.Item>
                :
                <Form.Item style={{ cursor: "no-drop" }}>
                  <Button
                    className="btn-sign-in"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", opacity: "0.6", pointerEvents: "none" }}
                  >
                    REGISTER
                  </Button>
                </Form.Item>
            }
          </Col>
        </Row>
        <Row justify='center'>
          <p>
            Already have an account?
          </p>
          &nbsp;
          <Link to="/auth/sign-in">
            Sign In
          </Link>
        </Row>
      </Form>

    const confirmPasswordForm =
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username"
          label="Confirmation Code"
          name="confirmationCode"
          rules={[
            {
              required: true,
              message: "Please fill in your confirmation code!",
            },
          ]}
        >
          <Input className="sign-input" placeholder="Enter confirmation code" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please fill in your new email!",
            },
            {
              validator: validateEmail,
            }
          ]}
        >
          <Input className="sign-input" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          className="username"
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please fill in your new password!",
            },
          ]}
        >
          <Input.Password
            value={passwordInput}
            onChange={changeSignUpPassword}
            className="sign-input"
            placeholder="Enter your new password" />
        </Form.Item>
        <Row justify="center" style={{ marginBottom: "15px" }}>
          <Col span={24}>
            {
              passwordInput && displayAlertPassword ?
                <>
                  {
                    noSpaceError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {NO_SPACE_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {NO_SPACE_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    lengthError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {LENGTH_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {LENGTH_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    lowercaseError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {LOWERCASE_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {LOWERCASE_ERROR}
                        </Text>
                      </>
                  }
                  <br></br>
                  {
                    noNumberError ?
                      <>
                        <span style={{ color: "#198754" }}><CheckOutlined /></span>&nbsp;
                        <Text type="success">
                          {NO_NUMBER_ERROR}
                        </Text>
                      </>
                      :
                      <>
                        <span style={{ color: "#FF0000" }}><CloseOutlined /></span>&nbsp;
                        <Text type="danger">
                          {NO_NUMBER_ERROR}
                        </Text>
                      </>
                  }
                </>
                :
                null
            }
          </Col>
        </Row>
        <Row justify='center'>
          <Col span={24}>
            {
              lengthError && lowercaseError && noSpaceError && noNumberError && displayButton ?
                <Form.Item>
                  <Button
                    className="btn-sign-in"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    CONFIRM
                  </Button>
                </Form.Item>
                :
                <Form.Item style={{ cursor: "no-drop" }}>
                  <Button
                    className="btn-sign-in"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", opacity: "0.6", pointerEvents: "none" }}
                  >
                    CONFIRM
                  </Button>
                </Form.Item>
            }
          </Col>
        </Row>
        <Row justify='center'>
          <Link to="/auth/sign-in">
            Back to Sign In
          </Link>
        </Row>
      </Form>

    const resetPasswordForm =
      <>
        <AlertBanner>
          <Alert
            className="alert"
            message="Enter your email and instructions will be sent to you!"
            type="info"
          >
          </Alert>
        </AlertBanner>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className="row-col"
        >
          <Form.Item
            className="username"
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please fill in your email!",
              },
              {
                validator: validateEmail
              },
            ]}
          >
            <Input className="sign-input" placeholder="Enter email" />
          </Form.Item>
          <Row justify='center'>
            <Col span={24}>
              <Form.Item>
                <Button
                  className="btn-sign-in"
                  type="primary"
                  htmlType="submit"
                >
                  RESET
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center'>
            <Link to="/auth/sign-in">
              Back to Sign In
            </Link>
          </Row>
        </Form>
      </>

    const confirmAccountForm =
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please fill in your email!",
            },
            {
              validator: validateEmail
            }
          ]}
        >
          <Input className="sign-input" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Confirmation Code"
          name="confirmationCode"
          rules={[
            {
              required: true,
              message: "Please fill in your confirmation code!",
            },
          ]}
        >
          <Input.Password className="sign-input" placeholder="Enter confirmation code" />
        </Form.Item>
        <Row justify="center">
          <Col span={24}>
            <Form.Item>
              <Button
                className="btn-sign-in"
                type="primary"
                htmlType="submit"
              >
                CONFIRM
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    const changePasswordForm = (
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please fill in your email!",
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input className="sign-input" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          className="username"
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "Please fill in your current password!",
            },
          ]}
        >
          <Input.Password
            className="sign-input"
            placeholder="Enter current password"
          />
        </Form.Item>
        <Form.Item
          className="username"
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please fill in your password!",
            },
          ]}
        >
          <Input.Password
            value={passwordInput}
            onChange={changeSignUpPassword}
            className="sign-input"
            placeholder="Enter your password"
          />
        </Form.Item>
        <Row justify="center" style={{ marginBottom: "15px" }}>
          <Col span={24}>
            {passwordInput && displayAlertPassword ? (
              <>
                {noSpaceError ? (
                  <>
                    <span style={{ color: "#198754" }}>
                      <CheckOutlined />
                    </span>
                    &nbsp;
                    <Text type="success">{NO_SPACE_ERROR}</Text>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#FF0000" }}>
                      <CloseOutlined />
                    </span>
                    &nbsp;
                    <Text type="danger">{NO_SPACE_ERROR}</Text>
                  </>
                )}
                <br></br>
                {lengthError ? (
                  <>
                    <span style={{ color: "#198754" }}>
                      <CheckOutlined />
                    </span>
                    &nbsp;
                    <Text type="success">{LENGTH_ERROR}</Text>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#FF0000" }}>
                      <CloseOutlined />
                    </span>
                    &nbsp;
                    <Text type="danger">{LENGTH_ERROR}</Text>
                  </>
                )}
                <br></br>
                {lowercaseError ? (
                  <>
                    <span style={{ color: "#198754" }}>
                      <CheckOutlined />
                    </span>
                    &nbsp;
                    <Text type="success">{LOWERCASE_ERROR}</Text>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#FF0000" }}>
                      <CloseOutlined />
                    </span>
                    &nbsp;
                    <Text type="danger">{LOWERCASE_ERROR}</Text>
                  </>
                )}
                <br></br>
                {noNumberError ? (
                  <>
                    <span style={{ color: "#198754" }}>
                      <CheckOutlined />
                    </span>
                    &nbsp;
                    <Text type="success">{NO_NUMBER_ERROR}</Text>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#FF0000" }}>
                      <CloseOutlined />
                    </span>
                    &nbsp;
                    <Text type="danger">{NO_NUMBER_ERROR}</Text>
                  </>
                )}
              </>
            ) : null}
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            {lengthError &&
            lowercaseError &&
            noSpaceError &&
            noNumberError &&
            displayButton ? (
              <Form.Item>
                <Button
                  className="btn-sign-in"
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  SAVE
                </Button>
              </Form.Item>
            ) : (
              <Form.Item style={{ cursor: "no-drop" }}>
                <Button
                  className="btn-sign-in"
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    opacity: "0.6",
                    pointerEvents: "none",
                  }}
                >
                  SAVE
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    );

    switch (pageParams) {
      case SIGN_IN:
        return (
          signInForm
        )
      case SIGN_UP:
        return (
          signUpForm
        )
      case CONFIRM_PASSWORD:
        return (
          confirmPasswordForm
        )
      case RESET_PASSWORD:
        return (
          resetPasswordForm
        )
      case CONFIRM_ACCOUNT:
        return (
          confirmAccountForm
        )
        case CHANGE_PASSWORD:
        return (
          changePasswordForm
        )
      default:
        break;
    }
  }

  const checkLoading = () =>
    !!(authStore.isLoading);

  return (
    <SignInWrapper>
      <Layout
        className="layout-signin">
        <Content>
          <Row className="row-wrapper">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} className="signin">
                {renderTitle(authPage)}
                {renderContent(authPage)}
              </Col>
            </Row>
          </Row>
        </Content>
      </Layout>
      {checkLoading() ? (
        <LoadingSpinner theme={"appTheme"} type={"page"} />
      ) : null}
    </SignInWrapper>
  );
};

export default inject("userStore", "authStore")(observer(AuthenticationPage));
