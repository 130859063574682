import { Layout } from "antd";
import { AdminTemplate, ContainerDashboard, SiderResponsive, SiderWrapper } from './style';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { Link } from "react-router-dom";
import {
  GlobalOutlined,
  ShopOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  ProjectOutlined,
  PushpinOutlined
} from '@ant-design/icons';
import Sidenav from "./Sidebar/Sidenav";
import Header from "./Header/Header";
import BreadcrumbComponent from "./Breadcrumb/Breadcrumb";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";

// eslint-disable-next-line no-unused-vars
const { Header: Content, Sider } = Layout;

function AdminDashboard({ children, authStore, adminStore, userStore }) {
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const isCollapsedMobile = useMediaQuery({ query: '(max-width: 375px)' });

  let { pathname } = useLocation();
  if (pathname === "/admin") {
    pathname = pathname.replace("/", "");
  }
  else {
    pathname = pathname.replace("/admin", "admin");
  }

  useEffect(() => {
    if (isCollapsedMobile) {
      adminStore.setCollapsed(true);
    }
  }, [adminStore, isCollapsedMobile])

  const items = [
    {
      label: <Link to={`/admin/projects`}>Projects</Link>,
      path: `/admin/projects`,
      icon: <ProjectOutlined />,
      key: 'projects?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/folders`}>Folders</Link>,
      path: `/admin/folders`,
      icon: <GlobalOutlined />,
      key: `folders?page=1&limit=10`
    },
    {
      label: <Link to={`/admin/subfolders`}>Sub folder</Link>,
      path: `/admin/subfolders`,
      icon: <ShopOutlined />,
      key: 'subfolders?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/sub-subfolders`}>Sub-subfolder</Link>,
      path: `/admin/sub-subfolders`,
      icon: <ContainerOutlined />,
      key: 'sub-subfolders?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/model3ds`}>Model 3D</Link>,
      path: `/admin/model3ds`,
      icon: <DatabaseOutlined />,
      key: 'model3ds?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/pins`}>Pins</Link>,
      path: `/admin/pins`,
      icon: <PushpinOutlined />,
      key: 'pins?page=1&limit=10'
    },
  ]

  const itemsResponsive = [
    {
      label: <Link to={`/admin/projects`}><ProjectOutlined /></Link>,
      path: `/admin/projects`,
      key: 'projects?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/categories`}><GlobalOutlined /></Link>,
      path: `/admin/categories`,
      key: `categories?page=1&limit=10`
    },
    {
      label: <Link to={`/admin/sub-categories`}><ShopOutlined /></Link>,
      path: `/admin/sub-categories`,
      key: 'sub-categories?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/point-of-interests`}><ContainerOutlined /></Link>,
      path: `/admin/point-of-interests`,
      key: 'point-of-interests?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/model3ds`}><DatabaseOutlined /></Link>,
      path: `/admin/model3ds`,
      key: 'model3ds?page=1&limit=10'
    },
    {
      label: <Link to={`/admin/pins`}><PushpinOutlined /></Link>,
      path: `/admin/pins`,
      key: 'pins?page=1&limit=10'
    },
  ]

  const checkLoading = () =>
    !!(authStore.isLoading || adminStore.isLoading);

  return (
    userStore.currentUser?.role !== "admin" ?
      <LoadingSpinner theme={"appTheme"} type={"page"} /> :
      <AdminTemplate>
        <SiderWrapper>
          <Sider
            breakpoint={isMobile ? "xs" : "md"}
            collapsible
            trigger={null}
            collapsed={adminStore.collapsed ? 1 : 0}
            theme="light"
            className="admin-sider"
          >
            <Sidenav
              className="admin-sider"
              items={items}
              collapsed={adminStore.collapsed ? 1 : 0}
            />
          </Sider>
        </SiderWrapper>
        <SiderResponsive>
          <Sider
            breakpoint={isMobile ? "xs" : "md"}
            collapsible
            trigger={null}
            collapsed={adminStore.collapsed ? 1 : 0}
            collapsedWidth={0}
            width={adminStore.collapsed ? 0 : 80}
            theme="light"
            className="admin-sider"
          >
            <Sidenav
              className="admin-sider"
              items={itemsResponsive}
              collapsed={adminStore.collapsed ? 1 : 0}
            />
          </Sider>
        </SiderResponsive>
        <Layout>
          <Header collapsed={adminStore.collapsed ? 1 : 0} />
          <ContainerDashboard collapsed={adminStore.collapsed ? 1 : 0}>
            <BreadcrumbComponent collapsed={adminStore.collapsed ? 1 : 0} pathname={pathname} subName={pathname} />
            {children}
          </ContainerDashboard>
        </Layout>
        {checkLoading() ? (
          <LoadingSpinner theme={"appTheme"} type={"page"} />
        ) : null}
      </AdminTemplate>
  );
}

export default inject("userStore", 'authStore', 'adminStore')(observer(AdminDashboard));
