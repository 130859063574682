import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import { Row, Col, Table, Button, Input, Popconfirm, Tooltip, Switch } from "antd";
import debounce from 'lodash/debounce';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment';
// import { useMediaQuery } from "react-responsive";
import { inject, observer } from "mobx-react";
// import { toJS } from "mobx";
import { ButtonTable, ContentWrapper, ToolbarWrapper, TableContain, MainContain } from "../../AdminDashboard/style";
// import axios from "axios";
// import { apiUrl } from "../../../utils/config";

const Project3D = ({ adminStore, userStore, authStore }) => {
  // const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [totalDocs, setTotalDocs] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();

  window.onscroll = () => adminStore.onscrollSticky(window, document, "toolbar-sticky");

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const onChange = e => {
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    adminStore.setCurrentLimit(pageSize);
    adminStore.setCurrentPage(current);
    if (paramSearch && paramSearch !== "") {
      setSearchParams({ page: current, limit: pageSize, search: paramSearch });
    } else {
      setSearchParams({ page: current, limit: pageSize });
    }
  }

  const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
  const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit")) : 10;
  const paramSearch = searchParams.get("search") ? searchParams.get("search") : "";

  adminStore.setSearchInput(paramSearch);

  const debounceCalculate = debounce(function (value) {
    handleSearchUsers(value)
  }, 1000)

  // var cancelToken
  const handleSearchUsers = async e => {
    let search = e && e !== '' ? e.trim() : '';
    if (search && search !== "") {
      setSearchParams({ page: 1, limit: limit, search: search });
    } else {
      setSearchParams({ page: 1, limit: limit })
    }
    // if (search === '') {
    //   setSearchValue()
    // } else {
    //   setSearchValue(search)
    // }
    // if (typeof cancelToken !== typeof undefined) {
    //   cancelToken.cancel('Operation canceled due to new request.')
    // }
    // cancelToken = axios.CancelToken.source()
    // try {
    //   adminStore.setLoadingProgress(true)
    //   const response = await axios.get(
    //     `${apiUrl}/projects?page=${1}&limit=${limit}${search && search !== '' ? '&search=' + search : ''
    //     }`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${authStore.token}`,
    //       },
    //       cancelToken: cancelToken.token,
    //     }
    //   )
    //   if (response) {
    //     adminStore.setLoadingProgress(false)
    //     if (response?.data?.docs) {
    //       adminStore.setProjects(response?.data?.docs || [])
    //       setTotalDocs(response?.data?.totalDocs || 1)
    //     }
    //   }
    // } catch (error) {
    //   if (error) {
    //     adminStore.setLoadingProgress(false)
    //     adminStore.setProjects([])
    //   }
    // }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const hasSelected = selectedRowKeys.length > 0

  useEffect(() => {
    adminStore.setSelectedKeyMenu('projects?page=1&limit=10');
    const fetchData = async () => {
      await adminStore.getAllProjects(page, limit, paramSearch)
        .then(response => {
          if (response.docs) {
            adminStore.setProjects(response.docs);
            setTotalDocs(response.totalDocs);
          }
        })
        .catch(err => {
          adminStore.setLoadingProgress(false);
        })
    }
    fetchData();
  }, [adminStore, limit, page, paramSearch])

  const handleDeleteProject = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteProject(record)
      .then((response) => {
        const data = adminStore.projects.filter((x) => x.id !== response.id);
        adminStore.setProjects(data);
        adminStore.openNotification('bottomRight', 'success', 'Delete project successfully!')
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!')
      });
  };

  const deleteMultipleProject = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deleteMultipleProject(record)
      .then((response) => {
        const _projects = [...adminStore.projects];
        for (let i = 0; i < record.length; i++) {
          const index = _projects.findIndex((x) => x.id === record[i]);
          if (index >= 0) {
            _projects.splice(index, 1);
          }
        }
        setSelectedRowKeys([])
        adminStore.setProjects(_projects);
        adminStore.openNotification('bottomRight', 'success', 'Delete project successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const toEditPage = () => { }
  const toAddPage = () => {
    return navigate(`/admin/projects/new`)
  }

  const columns = [
    {
      title: "Name",
      key: 'name',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (record, obj) => {
        return <span> {
          <Link style={{ color: '#000' }} onClick={toEditPage} to={`/admin/projects/${obj.id}`}>
            {record}
          </Link>
        } </span>
      },
    },
    {
      title: 'Lat',
      dataIndex: 'lat',
      key: 'lat',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.lat || 0) - (b.lat || 0)
      },
      render: (record, obj) => {
        return <span> {record ? record : 0} </span>
      },
    },
    {
      title: "Long",
      dataIndex: 'long',
      key: 'Long',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.long || 0) - (b.long || 0)
      },
      render: (record, obj) => {
        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
      sorter: (a, b) => {
        return (a.height || 0) - (b.height || 0)
      },
      render: (record, obj) => {
        return (
          <span >{record ? record : 0}</span>
        )
      },
    },
    {
      title: "Created At",
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (record, obj) => {
        return <span> {moment(record).format('MMMM Do YYYY, h:mm:ss A')} </span>
      },
    },
    {
      title: 'Default',
      dataIndex: 'defaultStart',
      width :100,
      key: 'defaultStart',
      render: (record, obj) => {
        return (
          <Switch defaultChecked={record ? true : false} checkedChildren={"ON"} unCheckedChildren={"OFF"} style={{ pointerEvents: 'none' }} />
        )
      },
    },
    {
      title: "Action",
      dataIndex: 'id',
      key: 'event',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: record => {
        return (
          <Row type="flex" justify="center" key="action">
            <Tooltip placement="topLeft" title={'Edit'}>
              <Link onClick={toEditPage} to={`/admin/projects/${record}`}>
                <ButtonTable>
                  <EditOutlined />
                </ButtonTable>
              </Link>
            </Tooltip>
            <Tooltip placement="topLeft" title={'Delete'}>
              <Popconfirm
                placement="left"
                title={"Are you sure delete this record?"}
                onConfirm={() => handleDeleteProject(record)}
                okText="Yes"
                cancelText="No"
              >
                <ButtonTable>
                  <DeleteOutlined />
                </ButtonTable>
              </Popconfirm>
            </Tooltip>
          </Row>
        );
      }
    }
  ]

  return (
    <AdminDashboard>
      <ContentWrapper >
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Col xs={24} sm={24} md={12} lg={12}>
              <Input
                onChange={onChange}
                className="action-search"
                placeholder=" Search..."
                prefix={<SearchOutlined />}
                defaultValue={paramSearch}
                allowClear={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "end" }}>
              {
                hasSelected &&
                <Button
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  style={{ paddingLeft: 17, paddingRight: 17 }}
                  onClick={() => deleteMultipleProject(selectedRowKeys)}
                >
                  DELETE
                </Button>
              }
              <Button
                type="primary"
                className="action-btn"
                icon={<PlusCircleOutlined />}
                style={{ marginLeft: 2 }}
                onClick={toAddPage}
              >
                ADD NEW
              </Button>

            </Col>
          </ToolbarWrapper>
        </div>
        <MainContain >
          <TableContain>
            <Col span={24} >
              <Table
                bordered
                size="default"
                columns={columns}
                dataSource={adminStore.projects || []}
                rowSelection={rowSelection}
                rowKey={record => record.id}
                scroll={{ x: 1000 }}
                pagination={{
                  defaultPageSize: limit,
                  showSizeChanger: true,
                  current: page,
                  pageSize: limit,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  locale: { items_per_page: "" },
                  total: totalDocs || 1,
                  onChange: (page, pageSize) =>
                    handleChangePagination(page, pageSize)
                }}
                locale={{ emptyText: "No data" }}
                className="border-space"
              />
            </Col>
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject('userStore', 'adminStore', 'authStore')(observer(Project3D));
