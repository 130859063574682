import { Menu } from 'antd';
import styled from 'styled-components';

export const MenuWrapper = styled(Menu)`
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
    ${props => props.collapsed ? `display:none;` : `display:inherit;`}
  }
  /* Track */
  ::-webkit-scrollbar-track {
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D1D6DB;
    display: none;
    width: 0px;
    border-radius: 15px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {

  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #D1D6DB;
      display: inherit;
      width: 5px;
    }
    ::-webkit-scrollbar {
    width: 5px;
    ${props => props.collapsed ? `display:none;` : `display:inherit;`}
    }
  }
  @media only screen and (max-width: 768px) {
    overflow: hidden;
    text-align: center;
  }
`;

export const SidebarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .logo-img {
    width: 200px;
    height: 60px;
    @media only screen and (max-width: 768px) {
      width: ${props => props.collapsed ? '0' : `80px`};
    }
  }
`

export const AvatarWrapper = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width:  ${props => props.collapsed ? '80px' : `150px`};
    .ant-avatar>img{
      object-fit: contain;
    }
`

export const IconWrapper = styled.div`
    background: rgb(255, 255, 255);
    width: 50px;
    display : flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      display: none !important;
    }
`