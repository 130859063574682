import styled from 'styled-components'
import { Avatar ,Row } from 'antd';

export const ToolbarRightContainer = styled.div`
  display: block;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 3px;
  z-index: 99;
  background: none;
  
  .ant-avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .ant-avatar {
      border: 1px solid #000;
    }
  }
  
`

export const ToolbarRightItem = styled.div`
  margin-bottom: 4px;
  position : relative;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  } 
  display : flex;
  flex-wrap : wrap;

  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .ant-avatar.ant-avatar-icon{
    display :flex;
    justify-content : center;
    align-items : center;
  }
  .ant-avatar.ant-avatar-icon>.anticon{
    font-size :22px;
  }
`
export const AvatarCustom = styled(Avatar)`
  margin-right: 5px !important;
  cursor: pointer; 
  background: ${props => props.activated === 0 ? '#303336' : '#00acd2'} !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  color: #FFFFFF !important;
  transition: ease .3s;
  path {
    fill: #FFFFFF !important;
    transition: ease .3s;
  }
`
export const ActionWalkWrapper = styled(Row)`
  display: flex;
  position: absolute;
  right: 100%;
  top: 0px;
  z-index: 9999;
  flex-wrap :nowrap;
  `