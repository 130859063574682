import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import { Col, Input, Form, Row, Select, Button, Popconfirm, Table, Checkbox, Modal, InputNumber } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ContentWrapper,
  ToolbarButton,
  ToolbarWrapper,
  MainContain,
  TableContain,
  ContainRelation,
  WidgetControl,
} from "../../AdminDashboard/style";
import TextArea from "antd/lib/input/TextArea";
import { toJS } from "mobx";
import GroupItem from "../../AdminDashboard/GroupItem";
import ModalAddPin from "../ModalAddPin";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  labelAlign: "left",
  wrapperCol: {
    span: 24,
  },
  layout: "vertical",
};

const validateMessages = {
  required: "${label} is required!",
};

const EditCategory = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addSubFolderForm] = Form.useForm();
  const { adminStore } = props;
  const { categoryId } = useParams();
  const [projects, setProjects] = useState([]);
  const [icons] = useState([
    {
      value: "GlobalOutlined",
      label: "Global",
    },
    {
      value: "ShopOutlined",
      label: "Shop",
    },
  ]);
  const [subcategories, setSubCategories] = useState([]);
  const [subfolders, setSubFolders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [ionIds, setIonIds] = useState([]);
  const [folderDetail, setFolderDetail] = useState(null);
  const [inputIonModal, setInputIonModal] = useState(true);
  const [pins, setPins] = useState([]);
  const [imageUrl, setImageUrl] = useState(null)

  window.onscroll = () =>
    adminStore.onscrollSticky(window, document, "toolbar-sticky");

  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;

  const onBtnCancelClick = () => {
    navigate(
      `/admin/folders?page=${page}&limit=${limit}${search && search !== "" ? "&search=" + search : ""
      }`
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      adminStore.getIonIds().then(res => {
        setIonIds(res)
      })
      await adminStore.getAllSubCategorys(1, 100).then((res) => {
        setSubCategories(res.docs);
      });
      await adminStore.getAllProjects(1, 100).then((res) => {
        setProjects(res.docs);
      });
      if (categoryId === "new") {
        form.resetFields();
      } else {
        await adminStore.getCategoryDetails(categoryId).then((response) => {
          setFolderDetail(response)
          setSubFolders(response.subcategories || [])
          if (response.pin) {
            setPins(response.pin || [])
          }
          adminStore.setCategoryDetails(response);
          form.setFieldsValue({
            title: response?.title,
            order: response?.order || 0,
            desc: response?.desc,
            icon: response?.icon,
            projectId: response?.projectId,
            lat: response?.lat || 0,
            long: response?.long || 0,
            height: response?.height || 0,
            heading: response?.heading || 0,
            pitch: response?.pitch || 0,
            roll: response?.roll || 0
          });
        });
      }
    };
    adminStore.setSelectedKeyMenu(`folders?page=1&limit=10`);
    fetchData();
    return () => {
      adminStore.setCategoryDetails([]);
      form.resetFields();
      setProjects([])
      setSubCategories([])
      setSubFolders([])
      setPins([]);
      setImageUrl(null)
    };
  }, [categoryId]);

  const setSubFolderDetail = (id, value) => {
    let index = subcategories.findIndex(c => c.id === id)
    if (index > -1) {
      let c = [...subcategories]
      c[index].categoryId = value
      setSubCategories(c)
    }
  }

  const handleAddSubCategory = () => {
    addSubFolderForm.validateFields().then(async values => {
      if (values.useExisting) {
        adminStore.setLoadingProgress(true);
        let payload = {
          categoryId: categoryId
        }
        let data = subcategories.find(c => c.id === values.subfolder)
        if (data) {
          payload.desc = data.desc
          payload.order = data.order
          payload.ionAccess = data.ionAccess
          payload.orientation = data.orientation
          payload.properties = data.properties
          payload.fill = data.fill
          payload.stroke = data.stroke
          payload.title = data.title
          payload.type = data.type
          payload.height = data.height
        }
        await adminStore
          .updateSubCategory(values.subfolder, payload)
          .then((res) => {
            let isExist = subfolders.find(c => c.id === values.subfolder)
            if (!isExist) {
              setSubFolders(subfolders => [
                ...subfolders,
                res
              ])
            }
            setSubFolderDetail(values.subfolder, categoryId)
            handleCancel()
            adminStore.openNotification(
              "bottomRight",
              "success",
              "Add sub-folder to folder successfully!"
            );
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification(
              "bottomRight",
              "error",
              "Something went wrong!"
            );
          });
      } else {
        let payload = {
          title: values?.title || "",
          order: values?.order < 0 ? 0 : values?.order || 0,
          desc: values?.desc || "",
          ionAccess: values?.ionAccess || null,
          type: values?.type,
          height: values?.height,
          categoryId: categoryId,
          orientation: {
            heading: values?.orientationheading || 0,
            pitch: values?.orientationpitch || 0,
            roll: values?.orientationroll || 0,
            lat: values?.orientationlat || 0,
            lon: values?.orientationlon || 0,
            height: values?.orientationheight || 0
          },
          properties: {
            title: values?.propertiestitle || "",
            description: values?.propertiesdescription || "",
            image: imageUrl || ""
          },
          fill: {
            color: values?.fillcolor,
            opacity: values?.fillopacity
          },
          stroke: {
            width: values?.strokewidth,
            color: values?.strokecolor,
            opacity: values?.strokeopacity
          }
        };
        adminStore.setLoadingProgress(true);
        await adminStore
          .createSubCategory(payload)
          .then((res) => {
            setSubFolders(subfolders => [
              ...subfolders,
              res
            ])
            setSubCategories(subcategories => [
              ...subcategories,
              res
            ])
            adminStore.setLoadingProgress(false);
            handleCancel()
            adminStore.openNotification(
              "bottomRight",
              "success",
              "Create new sub-folder successfully!"
            );
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification(
              "bottomRight",
              "error",
              "Something went wrong!"
            );
          });
      }
    })
  };


  const confirmRemoveSubFolder = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updateSubCategory(record.id, { categoryId: null })
      .then((response) => {
        setSubFolders(subfolders.filter(c => c.id !== record.id))
        setSubFolderDetail(record.id, null)
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'success', 'Remove sub-category successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      })
  }

  const handleOpenAddSubFolderModal = () => {
    setIsModalOpen(true);
  };

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    addSubFolderForm.resetFields()
    setChecked(true)
    setInputIonModal(true);
    setImageUrl(null)
  };

  const titleOnClick = () => {
    localStorage.setItem("folderDetail", JSON.stringify(folderDetail));
    localStorage.setItem("folder", JSON.stringify(folderDetail))
  }

  const columnsubcategories = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a href={`/admin/subfolders/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 80,
      align: "center",
      render: (record, obj) => {
        return <span> {record ? record : 0} </span>
      }
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this subfolder from folder?'}
            onConfirm={() => confirmRemoveSubFolder(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onFinish = () => {
    form.validateFields().then((values) => {
      const payload = {
        title: values?.title,
        order: values?.order < 0 ? 0 : values?.order || 0,
        desc: values?.desc || "",
        icon: values?.icon,
        projectId: values?.projectId || null,
        lat: parseFloat(values?.lat || 0),
        long: parseFloat(values?.long || 0),
        height: parseFloat(values?.height || 0),
        heading: parseFloat(values?.heading || 0),
        pitch: parseFloat(values?.pitch || 0),
        roll: parseFloat(values?.roll || 0)
      };
      if (categoryId === "new") {
        return handleCreateCategory(payload);
      } else {
        return handleUpdateCategory(categoryId, payload);
      }
    });
  };

  const handleCreateCategory = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .createNewCategory(payload)
      .then((res) => {
        adminStore.setLoadingProgress(false);
        onBtnCancelClick();
        adminStore.openNotification(
          "bottomRight",
          "success",
          "Create new category successfully!"
        );
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification(
          "bottomRight",
          "error",
          "Something went wrong!"
        );
      });
  };

  const handleUpdateCategory = async (id, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updateCategory(id, payload)
      .then((res) => {
        adminStore.setCategoryDetails(res);
        adminStore.openNotification(
          "bottomRight",
          "success",
          "Update this category successfully!"
        );
        onBtnCancelClick();
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification(
          "bottomRight",
          "error",
          "Something went wrong!"
        );
      });
  };

  const handleDeleteCategory = async () => {
    if (categoryId && categoryId !== "new") {
      adminStore.setLoadingProgress(true);
      await adminStore
        .deleteCategory(categoryId)
        .then((res) => {
          adminStore.openNotification(
            "bottomRight",
            "success",
            "Delete category successfully!"
          );
          onBtnCancelClick();
        })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification(
            "bottomRight",
            "error",
            "Something went wrong!"
          );
        });
    } else {
      adminStore.openNotification(
        "bottomRight",
        "error",
        "Something went wrong!"
      );
    }
  };

  const Relation = ({ title, children, action, icon, handleClick }) => {
    return (
      <div className="relation">
        <Row style={{ paddingBottom: "5px" }} justify="space-between">
          <div>{title}</div>
          {
            action && categoryId !== 'new' && (
              <Button onClick={() => handleClick()}>
                {icon}
                {action}
              </Button>
            )
          }
        </Row>
        {children}
      </div>
    );
  };

  const onSelectChangeModal = (value) => {
    if (value === 'place') {
      setInputIonModal(false);
    } else {
      setInputIonModal(true);
    }
  }

  const handleOpenAddPinModal = () => {
    adminStore.setOpenPinModal(true);
  }

  const columnPins = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/pins/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmDeletePin(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const confirmDeletePin = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore.deletePin(record.id).then((response) => {
      setPins(pins.filter(pin => pin.id !== record.id));
      adminStore.openNotification('bottomRight', 'success', 'Delete pin successfully!');
    })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }

  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="table-btn"
                icon={<CloseCircleOutlined />}
                onClick={onBtnCancelClick}
              >
                CANCEL
              </ToolbarButton>
              {categoryId !== "new" && (
                <ToolbarButton
                  type="danger"
                  className="table-btn"
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteCategory}
                >
                  DELETE
                </ToolbarButton>
              )}
              <ToolbarButton
                type="primary"
                className="table-btn"
                onClick={onFinish}
                icon={
                  categoryId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {categoryId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Col span={24}>
              <Form
                {...layout}
                form={form}
                name="nest-messages"
                validateMessages={validateMessages}
              >
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          name={["title"]}
                          label="Title"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            className="form-input"
                            placeholder="Please input title"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={"Project"}
                          name="projectId"
                          rules={[
                            {
                              required: false,
                              message: "Please select a project",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={"Please select a project"}
                            allowClear={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {projects.map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name={["order"]}
                          label="Order"
                          rules={[
                            {
                              required: false
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            min={0}
                            className="form-input"
                            placeholder="Please input order number"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["desc"]} label="Description">
                          <TextArea
                            rows={4}
                            className="form-input"
                            placeholder="Please input description"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["lat"]} label="Lat" rules={[
                          {
                            required: false,
                            message: "Please input lat",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input lat"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["long"]} label="Long" rules={[
                          {
                            required: false,
                            message: "Please input long",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input long"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["height"]} label="Height" rules={[
                          {
                            required: false,
                            message: "Please input height",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input height"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["heading"]} label="Heading" rules={[
                          {
                            required: false,
                            message: "Please input heading",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input heading"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["pitch"]} label="Pitch" rules={[
                          {
                            required: false,
                            message: "Please input pitch",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input pitch"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["roll"]} label="Roll" rules={[
                          {
                            required: false,
                            message: "Please input roll",
                          },
                        ]}>
                          <Input
                            type="number"
                            className="form-input"
                            placeholder="Please input roll"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={"Icon"}
                          name="icon"
                          rules={[
                            {
                              required: true,
                              message: "Please select a icon",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={"Please select a icon"}
                            allowClear={true}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {icons.map((v, i) => {
                              return (
                                <Option key={v.value} value={v.value}>
                                  {v.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <ContainRelation>
                      <Row>
                        <WidgetControl span={24}>
                          <Relation handleClick={handleOpenAddSubFolderModal} title={"SUB FOLDERS"} action={'Add sub folder'} icon={<PlusOutlined />}
                          >
                            <Table
                              rowKey={(record) => record.id}
                              bordered
                              pagination={false}
                              columns={columnsubcategories}
                              dataSource={subfolders.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)}
                              scroll={{ y: subfolders.length > 5 ? 213 : null }}
                            />
                          </Relation>
                          <Relation
                            handleClick={handleOpenAddPinModal}
                            title={"PIN"}
                            action={"Add pin"}
                            icon={<PlusOutlined />}
                          >
                            <Table
                              rowKey={(record) => record.id}
                              bordered
                              pagination={false}
                              columns={columnPins}
                              dataSource={pins}
                              scroll={{ y: pins.length > 5 ? 213 : null }}
                            />
                          </Relation>
                        </WidgetControl>
                      </Row>
                    </ContainRelation>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Modal
              title="Add sub-folder into folder"
              zIndex={9999}
              open={isModalOpen}
              onOk={handleAddSubCategory}
              onCancel={handleCancel}
              maskClosable={false}
              className='modal-add-folder'
              bodyStyle={{ overflowY: 'scroll' }}
            >
              <Form
                name="add-sub-folder-toproject"
                form={addSubFolderForm}
                layout="vertical"
                labelAlign="left"
              >
                <Form.Item
                  name="useExisting"
                  valuePropName="checked"
                  initialValue={true}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox checked={checked} onChange={onChangeCheckbox}>Use existing sub-folder</Checkbox>
                </Form.Item>
                {
                  checked && (
                    <Form.Item
                      label={"Sub folder"}
                      name="subfolder"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select folder",
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          "Please select folder"
                        }
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          subcategories.filter(c => (!c.categoryId))
                            .map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.title}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  )
                }
                {
                  !checked && (
                    <>
                      <Form.Item
                        name={["title"]}
                        label="Title"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please input title"
                        />
                      </Form.Item>
                      <Form.Item
                        name={["order"]}
                        label="Order"
                        rules={[
                          {
                            required: false
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          min={0}
                          className="form-input"
                          placeholder="Please input order number"
                        />
                      </Form.Item>
                      <Form.Item name={["desc"]} label="Description">
                        <TextArea
                          rows={4}
                          className="form-input"
                          placeholder="Please input description"
                        />
                      </Form.Item>
                      <Form.Item name={'type'} label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Please input ionAccess",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            { value: 'ion', label: 'ION 3DTiles' },
                            { value: 'geojson', label: 'ION Geojson' },
                            { value: 'imagery', label: 'ION Imagery' },
                            { value: 'czml', label: 'ION Czml' },
                            { value: 'place', label: 'Place' }
                          ]}
                          onChange={onSelectChangeModal}
                        />
                      </Form.Item>
                      {
                        inputIonModal && (
                          <Form.Item
                            label={"ION ID"}
                            name="ionAccess"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Please select a ionid",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder={
                                "Please select a ionid"
                              }
                              allowClear={true}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {ionIds.map((v, i) => {
                                return (
                                  <Option key={v.id} value={v.id}>
                                    {`${v.name} (${v.id})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )
                      }
                      <Form.Item name={"height"} label="Height">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                      <GroupItem title={"orientation"}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationlat"} label="Lat">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationlon"]} label="Lon">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheight"} label="Height">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheading"} label="Heading">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationpitch"]} label="Pitch">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationroll"} label="Roll">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"properties"}>
                        <Col span={24}>
                          <Form.Item name={"propertiestitle"} label="Title">
                            <Input placeholder="Please input properties title" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={["propertiesdescription"]} label="Description">
                            <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <UploadImage imageUrl={imageUrl} setImageUrl={setImageUrl} propertiesName={'propertiesimage'}/>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"Stroke"}>
                        <Col span={12}>
                          <Form.Item name={"strokewidth"} label="Witdh">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={["strokecolor"]} label="Color">
                            <input
                              style={{ width: '150px', height: '150px' }}
                              type="color"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={"strokeopacity"} label="Opacity">
                            <InputNumber max={1} min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"Fill"}>
                        <Col span={24}>
                          <Form.Item name={"fillcolor"} label="Color">
                            <input
                              style={{ width: '150px', height: '150px' }}
                              type="color"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={["fillopacity"]} label="Opacity">
                            <InputNumber max={1} min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                    </>
                  )
                }
              </Form>
            </Modal>
            <ModalAddPin setPinsFolder={setPins} folderId={categoryId} />
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject("adminStore", "authStore")(observer(EditCategory));
