import { inject, observer } from 'mobx-react';
import React, { } from 'react';
import { BlockWrapper, Spinner } from "./style";

const LoadingSpinner = (props) => {

    const theme = {
        name: 'default',
        solidColor: '#00acd2 ',
        solidLightColor: '#ecf9f6',
        gradientColor: 'linear-gradient(167.51deg, #00acd2  24.37%, #00acd2  78.07%)',
        shadowColor: '0 2px 10px rgba(46,207,148,0.6)',
    }

    const blockStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(0)',
        zIndex : 9999
    }

    const pageAnimation = () => {
        return (
            <style>
                {`
            @keyframes loading-spinner {
              0% { transform : translate(-50%, -50%) rotate(0); }
              100% { transform : translate(-50%, -50%) rotate(360deg); }
            }
            `}
            </style>
        )
    }
    return (
        <React.Fragment>
            <BlockWrapper>
                <Spinner theme={theme} style={blockStyle} />
                {pageAnimation()}
            </BlockWrapper>
        </React.Fragment>
    )
}
export default inject('userStore')(observer(LoadingSpinner));
