import { Row, Col, Slider, InputNumber, Checkbox, Button } from 'antd';
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { BloomContainer, ContainerWrappers } from './style';
import { useMediaQuery } from 'react-responsive';
import { toJS } from 'mobx';
const Cesium = require('cesium')
const BloomControl = ({ scene, viewer, globe, projectStore, skyAtmosphere }) => {
    const [tabActive, setTabActive] = useState('ground')
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const handleCancel = () => {
        projectStore.setCurrentToolControl('')
    }
    const changeDataViewer = (key , newValue ,firstAccess = false) => {
        if (key === "enableTerrain" && !firstAccess) {
            if (newValue) {
                scene.setTerrain(Cesium.Terrain.fromWorldTerrain());
            } else {
                scene.terrainProvider = new Cesium.EllipsoidTerrainProvider();
            }
        }
        else if (key === "showFog") {
            scene.fog.enabled = newValue;
        }
        else if (key === "enableLighting") {
            globe.enableLighting = newValue;
        }
        else if (key === "showGroundAtmosphere") {
            globe.showGroundAtmosphere = newValue;
        }
        else if (key === "dynamicLighting") {
            globe.dynamicAtmosphereLighting = newValue;
        }
        else if (key === "dynamicLightingFromSun") {
            globe.dynamicAtmosphereLightingFromSun = newValue;
        }
        else if (key === "density") {
            viewer.scene.fog.density = 2.0e-4 * newValue;
        }
        else if (key === "density") {
            viewer.scene.fog.density = 2.0e-4 * newValue;
        }
        else if (key === "minimumBrightness") {
            viewer.scene.fog.minimumBrightness = newValue;
        }
        else if (key === "groundAtmosphereLightIntensity") {
            globe.atmosphereLightIntensity = parseFloat(newValue);
        }
        else if (key === "groundAtmosphereRayleighCoefficientR") {
            globe.atmosphereRayleighCoefficient.x = parseFloat(newValue) * 1e-6;
        }
        else if (key === "groundAtmosphereRayleighCoefficientG") {
            globe.atmosphereRayleighCoefficient.y = parseFloat(newValue) * 1e-6;
        }
        else if (key === "groundAtmosphereRayleighCoefficientB") {
            globe.atmosphereRayleighCoefficient.z = parseFloat(newValue) * 1e-6;
        }
        else if (key === "groundAtmosphereMieCoefficient") {
            const v = parseFloat(newValue) * 1e-6;
            globe.atmosphereMieCoefficient = new Cesium.Cartesian3(v, v, v);
        }
        else if (key === "groundAtmosphereRayleighScaleHeight") {
            globe.atmosphereRayleighScaleHeight = parseFloat(newValue);
        }
        else if (key === "groundAtmosphereMieScaleHeight") {
            globe.atmosphereMieScaleHeight = parseFloat(newValue);
        }
        else if (key === "groundAtmosphereMieAnisotropy") {
            globe.atmosphereMieAnisotropy = parseFloat(newValue);
        }
        else if (key === "groundHueShift") {
            globe.atmosphereHueShift = parseFloat(newValue);
        }
        else if (key === "groundSaturationShift") {
            globe.atmosphereSaturationShift = parseFloat(newValue);
        }
        else if (key === "groundBrightnessShift") {
            globe.atmosphereBrightnessShift = parseFloat(newValue);
        }
        else if (key === "lightingFadeOutDistance") {
            globe.lightingFadeOutDistance = parseFloat(newValue);
        }
        else if (key === "lightingFadeInDistance") {
            globe.lightingFadeInDistance = parseFloat(newValue);
        }
        else if (key === "nightFadeOutDistance") {
            globe.nightFadeOutDistance = parseFloat(newValue);
        }
        else if (key === "nightFadeInDistance") {
            globe.nightFadeInDistance = parseFloat(newValue);
        }
        else if (key === "showSkyAtmosphere") {
            skyAtmosphere.show = newValue;
        }
        else if (key === "skyAtmosphereLightIntensity") {
            skyAtmosphere.atmosphereLightIntensity = parseFloat(newValue);
        }
        else if (key === "skyAtmosphereRayleighCoefficientR") {
            skyAtmosphere.atmosphereRayleighCoefficient.x =
                parseFloat(newValue) * 1e-6;
        }
        else if (key === "skyAtmosphereRayleighCoefficientG") {
            skyAtmosphere.atmosphereRayleighCoefficient.y =
                parseFloat(newValue) * 1e-6;
        }
        else if (key === "skyAtmosphereRayleighCoefficientB") {
            skyAtmosphere.atmosphereRayleighCoefficient.z =
                parseFloat(newValue) * 1e-6;
        }
        else if (key === "skyAtmosphereMieCoefficient") {
            const v = parseFloat(newValue) * 1e-6;
            skyAtmosphere.atmosphereMieCoefficient = new Cesium.Cartesian3(
                v,
                v,
                v
            );
        }
        else if (key === "skyAtmosphereRayleighScaleHeight") {
            skyAtmosphere.atmosphereRayleighScaleHeight = parseFloat(newValue);
        }
        else if (key === "skyAtmosphereMieScaleHeight") {
            skyAtmosphere.atmosphereMieScaleHeight = parseFloat(newValue);
        }
        else if (key === "skyAtmosphereMieAnisotropy") {
            skyAtmosphere.atmosphereMieAnisotropy = parseFloat(newValue);
        }
        else if (key === "skyHueShift") {
            skyAtmosphere.hueShift = parseFloat(newValue);
        }
        else if (key === "skySaturationShift") {
            skyAtmosphere.saturationShift = parseFloat(newValue);
        }
        else if (key === "skyBrightnessShift") {
            skyAtmosphere.brightnessShift = parseFloat(newValue);
        }
        else if (key === "perFragmentAtmosphere") {
            scene.skyAtmosphere.perFragmentAtmosphere = newValue;
        }
        else if (key === "hdr") {
            scene.highDynamicRange = newValue;
        }
        else if (key === "groundTranslucency") {
            globe.translucency.enabled = newValue;
            globe.translucency.frontFaceAlpha = 0.1;
            globe.translucency.backFaceAlpha = 0.1;
        }
    }
    
    useEffect(() => {
        Object.keys(projectStore.viewModelBloom).forEach(function (key) {
            let newValue = projectStore.viewModelBloom[key]
            changeDataViewer(key, newValue, true)
        });
    }, [])

    const onChangeBloom = (key, newValue) => {
        projectStore.setViewModelBloom(key, newValue)
        changeDataViewer(key, newValue)
    }

    return (
        <BloomContainer style={isMobile ? { width: '80%' } : { width: '30%' }}>
            <Row className="close-btn" span={24}>
                <span className='group-button-skybox' onClick={() => handleCancel()}>X</span>
            </Row>
            <ContainerWrappers>
                <Row>
                    <Col>
                        <strong>Bloom Effect</strong>
                    </Col>
                </Row>
                <div>
                    <div className="tab">
                        <Button
                            style={{ marginRight: 10 }}
                            type={tabActive === 'ground' ? 'primary' : ''}
                            onClick={() => setTabActive('ground')}
                        >
                            Ground Atmosphere
                        </Button>
                        <Button type={tabActive === 'sky' ? 'primary' : ''} onClick={() => setTabActive('sky')}>
                            Sky Atmosphere
                        </Button>
                    </div>
                    <table id="ground-table" className="tabcontent" style={tabActive === 'ground' ? {} : { display: "none" }}>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Fog Settings</th>
                            </tr>
                            <tr>
                                <td className='td-label'>Fog</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.showFog} onChange={(e) => onChangeBloom('showFog', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Fog Min Brightness</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={1.0}
                                        step={0.01}
                                        onChange={(e) => onChangeBloom('minimumBrightness', e)}
                                        value={projectStore.viewModelBloom.minimumBrightness || 0.1}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('minimumBrightness', e)}
                                        value={projectStore.viewModelBloom.minimumBrightness || 0.1}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Fog Density</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.5}
                                        max={2.0}
                                        step={0.1}
                                        value={projectStore.viewModelBloom.density || 0.1}
                                        onChange={(e) => onChangeBloom('density', e)}
                                        disabled={!projectStore.viewModelBloom.showFog}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        value={projectStore.viewModelBloom.density || 0.1}
                                        onChange={(e) => onChangeBloom('density', e)}
                                        disabled={!projectStore.viewModelBloom.showFog}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Ground Atmosphere Settings</th>
                            </tr>
                            <tr>
                                <td className='td-label'>Terrain</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.enableTerrain} onChange={(e) => onChangeBloom('enableTerrain', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Ground Atmosphere</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.showGroundAtmosphere} onChange={(e) => onChangeBloom('showGroundAtmosphere', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Light Intensity</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereLightIntensity', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereLightIntensity || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        value={projectStore.viewModelBloom.groundAtmosphereLightIntensity || 1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereLightIntensity', e)}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Red</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientR', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientR || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientR', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientR || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Green</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientG', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientG || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientG', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientG || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Blue</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientB', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientB || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighCoefficientB', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighCoefficientB || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Coefficient</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieCoefficient', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieCoefficient || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieCoefficient', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieCoefficient || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Scale Height</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0}
                                        max={2e4}
                                        step={1e2}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighScaleHeight', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereRayleighScaleHeight', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereRayleighScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Scale Height</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0}
                                        max={1e4}
                                        step={1e2}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieScaleHeight', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieScaleHeight', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Anisotropy</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1.0}
                                        max={1.0}
                                        step={0.1}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieAnisotropy', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieAnisotropy || 0.1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundAtmosphereMieAnisotropy', e)}
                                        value={projectStore.viewModelBloom.groundAtmosphereMieAnisotropy || 0.1}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Hue Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.01}
                                        onChange={(e) => onChangeBloom('groundHueShift', e)}
                                        value={projectStore.viewModelBloom.groundHueShift || 0.01}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber size={5}
                                        onChange={(e) => onChangeBloom('groundHueShift', e)}
                                        value={projectStore.viewModelBloom.groundHueShift || 0.01} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Saturation Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.01}
                                        onChange={(e) => onChangeBloom('groundSaturationShift', e)}
                                        value={projectStore.viewModelBloom.groundSaturationShift || 0.01}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        size={5}
                                        onChange={(e) => onChangeBloom('groundSaturationShift', e)}
                                        value={projectStore.viewModelBloom.groundSaturationShift || 0.01}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Brightness Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.01}
                                        onChange={(e) => onChangeBloom('skyBrightnessShift', e)}
                                        value={projectStore.viewModelBloom.skyBrightnessShift || 0.01}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber size={5}
                                        onChange={(e) => onChangeBloom('skyBrightnessShift', e)}
                                        value={projectStore.viewModelBloom.skyBrightnessShift || 0.01}
                                        disabled={!projectStore.viewModelBloom.showFog && !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Globe Lighting</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.enableLighting} onChange={(e) => onChangeBloom('enableLighting', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Lighting Fade Out Distance</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={1e6}
                                        max={1e8}
                                        step={1e6}
                                        onChange={(e) => onChangeBloom('lightingFadeOutDistance', e)}
                                        value={projectStore.viewModelBloom.lightingFadeOutDistance || 1}
                                        disabled={!projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        size={10}
                                        onChange={(e) => onChangeBloom('lightingFadeOutDistance', e)}
                                        value={projectStore.viewModelBloom.lightingFadeOutDistance || 0.01}
                                        disabled={!projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Lighting Fade In Distance</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={1e6}
                                        max={1e8}
                                        step={1e6}
                                        onChange={(e) => onChangeBloom('lightingFadeInDistance', e)}
                                        value={projectStore.viewModelBloom.lightingFadeInDistance || 1}
                                        disabled={!projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        size={10}
                                        onChange={(e) => onChangeBloom('lightingFadeInDistance', e)}
                                        value={projectStore.viewModelBloom.lightingFadeInDistance || 1}
                                        disabled={!projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Night Fade Out Distance</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={1e6}
                                        max={1e8}
                                        step={1e6}
                                        onChange={(e) => onChangeBloom('nightFadeOutDistance', e)}
                                        value={projectStore.viewModelBloom.nightFadeOutDistance || 1}
                                        disabled={!projectStore.viewModelBloom.enableLighting || !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                    <InputNumber
                                        size={10}
                                        onChange={(e) => onChangeBloom('nightFadeOutDistance', e)}
                                        value={projectStore.viewModelBloom.nightFadeOutDistance || 1}
                                        disabled={!projectStore.viewModelBloom.enableLighting || !projectStore.viewModelBloom.showGroundAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Night Fade In Distance</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={1e6}
                                        max={1e8}
                                        step={1e6}
                                        onChange={(e) => onChangeBloom('nightFadeInDistance', e)}
                                        value={projectStore.viewModelBloom.nightFadeInDistance || 1}
                                        disabled={!projectStore.viewModelBloom.enableLighting || (!projectStore.viewModelBloom.showGroundAtmosphere && !projectStore.viewModelBloom.enableTerrain)}
                                    />
                                    <InputNumber size={10}
                                        onChange={(e) => onChangeBloom('nightFadeInDistance', e)}
                                        value={projectStore.viewModelBloom.nightFadeInDistance || 1}
                                        disabled={!projectStore.viewModelBloom.enableLighting || (!projectStore.viewModelBloom.showGroundAtmosphere && !projectStore.viewModelBloom.enableTerrain)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Dynamic Atmosphere</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.dynamicLighting} onChange={(e) => onChangeBloom('dynamicLighting', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Dynamic Atmosphere From Sun</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.dynamicLightingFromSun} onChange={(e) => onChangeBloom('dynamicLightingFromSun', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Ground Translucency</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.groundTranslucency} onChange={(e) => onChangeBloom('groundTranslucency', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>HDR</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.hdr} onChange={(e) => onChangeBloom('hdr', e.target.checked)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="sky-table" className="tabcontent" style={tabActive === 'sky' ? {} : { display: "none" }}>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Sky Atmosphere Settings</th>
                            </tr>
                            <tr>
                                <td className='td-label'>Sky Atmosphere</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.showSkyAtmosphere} onChange={(e) => onChangeBloom('showSkyAtmosphere', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Light Intensity</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereLightIntensity', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereLightIntensity || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereLightIntensity', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereLightIntensity || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Red</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientR', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientR || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientR', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientR || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Green</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientG', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientG || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientG', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientG || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Coefficient: Blue</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientB', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientB || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighCoefficientB', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighCoefficientB || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Coefficient</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0.0}
                                        max={100.0}
                                        step={1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieCoefficient', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieCoefficient || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieCoefficient', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieCoefficient || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Rayleigh Scale Height</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0}
                                        max={2e4}
                                        step={1e2}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighScaleHeight', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereRayleighScaleHeight', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereRayleighScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Scale Height</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={0}
                                        max={1e4}
                                        step={1e2}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieScaleHeight', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieScaleHeight', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieScaleHeight || 1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Mie Anisotropy</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1.0}
                                        max={1.0}
                                        step={0.1}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieAnisotropy', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieAnisotropy || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber
                                        className="numinput"
                                        size={5}
                                        onChange={(e) => onChangeBloom('skyAtmosphereMieAnisotropy', e)}
                                        value={projectStore.viewModelBloom.skyAtmosphereMieAnisotropy || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Hue Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.1}
                                        onChange={(e) => onChangeBloom('skyHueShift', e)}
                                        value={projectStore.viewModelBloom.skyHueShift || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber size={5}
                                        onChange={(e) => onChangeBloom('skyHueShift', e)}
                                        value={projectStore.viewModelBloom.skyHueShift || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Saturation Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.1}
                                        onChange={(e) => onChangeBloom('skySaturationShift', e)}
                                        value={projectStore.viewModelBloom.skySaturationShift || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                    <InputNumber size={5}
                                        onChange={(e) => onChangeBloom('skySaturationShift', e)}
                                        value={projectStore.viewModelBloom.skySaturationShift || 0.1}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Brightness Shift</td>
                                <td className='td-input'>
                                    <Slider
                                        type="range"
                                        min={-1}
                                        max={1}
                                        step={0.1}
                                        onChange={(e) => onChangeBloom('skyBrightnessShift', e)}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                        value={projectStore.viewModelBloom.skyBrightnessShift || 0.1}
                                    />
                                    <InputNumber size={5}
                                        onChange={(e) => onChangeBloom('skyBrightnessShift', e)}
                                        disabled={!projectStore.viewModelBloom.showSkyAtmosphere}
                                        value={projectStore.viewModelBloom.skyBrightnessShift || 0.1}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>Per-Fragment</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.perFragmentAtmosphere} onChange={(e) => onChangeBloom('perFragmentAtmosphere', e.target.checked)} />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-label'>HDR</td>
                                <td className='td-input'>
                                    <Checkbox checked={projectStore.viewModelBloom.hdr} onChange={(e) => onChangeBloom('hdr', e.target.checked)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ContainerWrappers>
        </BloomContainer>
    );
};

export default inject("projectStore")(observer(BloomControl));
