import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { AvatarWrapper, IconWrapper, MenuWrapper, SidebarHeaderWrapper } from "./style";
import { MenuOutlined } from "@ant-design/icons";

const Sidenav = (props) => {
  const navigate = useNavigate();

  const {
    collapsed,
    adminStore,
    items,
  } = props;

  const onMenuClick = (path) => {
    adminStore.setSelectedKeyMenu(path);
    navigate(`/admin/${path}`);
  };

  const toggle = () => adminStore.setCollapsed(!adminStore.collapsed);

  return (
    <>
      <SidebarHeaderWrapper>
        <AvatarWrapper
          collapsed={collapsed ? 1 : 0}
        >
          <Avatar
            shape={collapsed ? 'square' : 'circle'}
            className="logo-img"
            src={
              "https://obt-test-eu.s3.eu-west-1.amazonaws.com/eco_cite_zenata_cdg_4a5185508d.jpg"
            }
            alt=""
          />
        </AvatarWrapper>
        <IconWrapper>
          <span
            className="trigger-sidebar"
            onClick={toggle}
            style={{display: collapsed ? "none" : "block"}}
          >
            <span><MenuOutlined /></span>
          </span>
        </IconWrapper>
      </SidebarHeaderWrapper>
      <MenuWrapper
        theme="light"
        mode="inline"
        selectedKeys={adminStore.selectedKeyMenu}
        onSelect={(k) => onMenuClick(k.key)}
        collapsed={collapsed ? 1 : 0}
        items={items}
      >
      </MenuWrapper>
    </>
  );
};

export default inject("adminStore")(observer(Sidenav));
