import styled from 'styled-components'
import { Button, Row } from 'antd';

export const Schedule = styled.div`
  background-color: rgba(222, 222, 222, 0.85);
  position: absolute;
  ${'' /* ${props => !props.takeViewportHeight && `height: calc(100vh - 10px);`}; */}
  z-index : 9999;
  top: 5px;
  ${'' /* bottom : 5px; */}
  right: 0px;
  border-radius: 5px 0 0 5px;
  overflow :hidden;
  .close-btn{
      display : flex;
      justify-content : flex-end;
      border-bottom: 1px solid #00acd2;
      color : #fff;
      span{
        padding : 5px;
        font-size : 16px;
        font-weight : bold;
        cursor : pointer;
        &:hover{
          color : #00acd2;
        }
      }
    }
    .ant-btn-primary{
        border-color :#00acd2 ;
        background : #00acd2 ;
        &:hover{
            border-color : #00acd2;
          color : #fff;
        }
    }
`

export const ButtonWrappers = styled(Row)`
    padding : 10px 0 0 20px ;
    width: 100% ;
`

export const ContainerWrappers = styled.div`
    padding : 20px 10px ;
`

export const ButtonCustom = styled(Button)`
    padding : 0 15px !important ;
    font-weight: 600;
    margin-top :4px;
    margin-bottom :4px;
    margin-left :4px;
`