import { Tooltip } from "antd";
import ReactDOM from 'react-dom/client';
import { inject, observer } from "mobx-react";
import React, { Fragment } from "react";
import { ReactComponent as OptimizeIcon } from "../../../../assets/images/setting.svg";
import { ReactComponent as MeasureDistance } from "../../../../assets/images/measure-distance-b0.svg";
import { ReactComponent as FlyWalk } from "../../../../assets/images/icon-fly-walk.svg";
import { ReactComponent as Shadows } from "../../../../assets/images/shadows-icon.svg";
import { ReactComponent as Schedule4D } from "../../../../assets/images/4d-icon.svg";
import { AvatarCustom, ToolbarRightItem, ToolbarRightContainer, ActionWalkWrapper } from "./style";
import { PauseCircleOutlined, RightCircleOutlined, RadarChartOutlined, DingdingOutlined, AntCloudOutlined, RiseOutlined } from "@ant-design/icons";
const Cesium = require('cesium')



const ToolbarRight = ({ viewer, projectStore ,flyHome }) => {
  var unsubscribeTicks
  const removeCZML = (add) => {
    viewer.clock.shouldAnimate = false;
    viewer.trackedEntity = undefined;
    if (unsubscribeTicks) {
      viewer.clock.onTick.removeEventListener(unsubscribeTicks);
    }
    // viewer.clock.onTick?._listeners?.filter((x) => x?.name === add)
    if (
      viewer.clock.onTick?._listeners?.filter((x) => x?.name == add)
    ) {
      viewer.clock.onTick._listeners =
        viewer.clock.onTick?._listeners?.filter((x) => x?.name != add);
    }
  }

  const runCZML = () => {
    if (!projectStore.volautoMode) {
      const currentTime = Cesium.JulianDate.now();
      viewer.clock.currentTime = currentTime;
      viewer.clock.startTime = currentTime;
      viewer.clock.stopTime = Cesium.JulianDate.addSeconds(currentTime, 1, new Cesium.JulianDate()); // Tăng stopTime lên 1 giây so với currentTime
      viewer.timeline.updateFromClock();
      viewer.timeline.zoomTo(viewer.clock.startTime, viewer.clock.stopTime);
      flyHome()
      return removeCZML('onVolauto')
    } else {
      viewer.trackedEntity = undefined;
      var dataSource = new Cesium.CzmlDataSource();
      var czmlPath = projectStore?.czml?.path ? (projectStore?.czml?.path + '/') : "https://modelszenata.s3.eu-west-3.amazonaws.com/";
      var vehicleEntity;
      viewer.dataSources.add(dataSource);
      var partsToLoad = [
        {
          url: projectStore?.czml?.url ? projectStore?.czml?.url : "MultipartVehicle_part.czml",
          range: [0, 1500],
          requested: false,
          loaded: false,
        },
      ];
      viewer.clock.shouldAnimate = true;
      //process each czml file
      async function processPart(part) {
        part.requested = true;
        await dataSource.process(czmlPath + part.url).then(function () {
          part.loaded = true;
          if (viewer.trackedEntity === undefined) {
            viewer.trackedEntity = vehicleEntity =
              dataSource.entities.getById("Vehicle");

          }
        });
      }
      //initial part to load
      processPart(partsToLoad[0]);
      var preloadTimeInSeconds = 100;
      //start the animation once the user clicks on play

      var onVolauto = (clock) => {
        var scratchCartesian = new Cesium.Cartesian3();
        var scratchCartographic = new Cesium.Cartographic();
        if (vehicleEntity) {
          var pos = vehicleEntity.position.getValue(
            clock.currentTime,
            scratchCartesian
          );
          if (pos) {
            var lla = Cesium.Cartographic.fromCartesian(
              pos,
              Cesium.Ellipsoid.WGS84,
              scratchCartographic
            );
            const heightString = lla.height.toFixed(4);
            const latitude = Cesium.Math.toDegrees(lla.latitude).toFixed(4);
            const longitude = Cesium.Math.toDegrees(lla.longitude).toFixed(4);
            projectStore.setCameraPosition({
              heading: projectStore?.cameraPosition?.heading,
              pitch: projectStore?.cameraPosition?.pitch,
              roll: projectStore?.cameraPosition?.roll,
              height: heightString,
              lat: latitude,
              long: longitude,
            });
          }
        }
        //changing the speed to 2
        clock.multiplier = 2.0;
        // get the time offset comparing to czml
        var timeOffset = Cesium.JulianDate.secondsDifference(
          clock.currentTime,
          clock.startTime
        );
        // the part to load that fits the time offset
        partsToLoad
          .filter(function (part) {
            return (
              !part.requested &&
              timeOffset >= part.range[0] - preloadTimeInSeconds &&
              timeOffset <= part.range[1]
            );
          })
          .forEach(function (part) {
            processPart(part);
          });
      }

      unsubscribeTicks = viewer.clock.onTick.addEventListener(onVolauto);
      // stop animation after the end of the part
      viewer.clock.onStop.addEventListener(function (clock) {
        viewer.animation.viewModel.pauseViewModel.command();
      });
    }
  };
  const visitemanuelleMode = () => {
    if (!projectStore.visitemanuelle) {
      document.removeEventListener("keydown")
      return removeCZML('visitemanuelle')
    } else {
      var planePrimitive;
      var pathPosition = new Cesium.SampledPositionProperty();
      var camera = viewer.camera;
      var scene = viewer.scene;
      var controller = scene.screenSpaceCameraController;
      var r = 0;
      var center = new Cesium.Cartesian3();
      var hpRoll = new Cesium.HeadingPitchRoll();
      var hpRange = new Cesium.HeadingPitchRange();
      var speed = 5;
      var deltaRadians = Cesium.Math.toRadians(3.0);
      var position = Cesium.Cartesian3.fromDegrees(
        -7.4773273454,
        33.6631224705,
        100.0
      );
      var speedVector = new Cesium.Cartesian3();
      var fixedFrameTransform = Cesium.Transforms.localFrameToFixedFrameGenerator(
        "south",
        "east"
      );
      planePrimitive = scene.primitives.add(
        Cesium.Model.fromGltf({
          url: "https://modelszenata.s3.eu-west-3.amazonaws.com/models/arrow.glb",
          modelMatrix: Cesium.Transforms.headingPitchRollToFixedFrame(
            position,
            hpRoll,
            Cesium.Ellipsoid.WGS84,
            fixedFrameTransform
          ),
          maximumScale: 0.00001,
        })
      );

      planePrimitive.readyPromise.then(function (model) {
        model.activeAnimations.addAll({
          multiplier: 0.5,
          loop: Cesium.ModelAnimationLoop.REPEAT,
        });
        r = 2.0 * Math.max(model.boundingSphere.radius, camera.frustum.near);
        controller.minimumZoomDistance = r;
        Cesium.Matrix4.multiplyByPoint(
          Cesium.Transforms.headingPitchRollToFixedFrame(
            position,
            hpRoll,
            Cesium.Ellipsoid.WGS84,
            fixedFrameTransform
          ),
          model.boundingSphere.center,
          center
        );
        var heading = Cesium.Math.toRadians(180.0);
        var pitch = Cesium.Math.toRadians(1.0);
        hpRange.heading = heading;
        hpRange.pitch = pitch;
        hpRange.range = r;
        camera.lookAt(center, hpRange);
        document.addEventListener("keydown", function (e) {
          switch (e.keyCode) {
            case 40:
              if (e.shiftKey) {
                speed = Math.max(--speed, 0);
              } else {
                hpRoll.pitch -= deltaRadians;
                if (hpRoll.pitch < -Cesium.Math.TWO_PI) {
                  hpRoll.pitch += Cesium.Math.TWO_PI;
                }
              }
              break;
            case 38:
              if (e.shiftKey) {
                speed = Math.min(++speed, 100);
              } else {
                hpRoll.pitch += deltaRadians;
                if (hpRoll.pitch > Cesium.Math.TWO_PI) {
                  hpRoll.pitch -= Cesium.Math.TWO_PI;
                }
              }
              break;
            case 39:
              hpRoll.heading += deltaRadians;
              if (hpRoll.heading > Cesium.Math.TWO_PI) {
                hpRoll.heading -= Cesium.Math.TWO_PI;
              }
              break;
            case 37:
              hpRoll.heading -= deltaRadians;
              if (hpRoll.heading < 0.0) {
                hpRoll.heading += Cesium.Math.TWO_PI;
              }
              break;
            default:
          }
        });

        viewer.scene.preUpdate.addEventListener(function (scene, time) {
          speedVector = Cesium.Cartesian3.multiplyByScalar(
            Cesium.Cartesian3.UNIT_X,
            speed / 10,
            speedVector
          );
          position = Cesium.Matrix4.multiplyByPoint(
            planePrimitive.modelMatrix,
            speedVector,
            position
          );
          pathPosition.addSample(Cesium.JulianDate.now(), position);
          Cesium.Transforms.headingPitchRollToFixedFrame(
            position,
            hpRoll,
            Cesium.Ellipsoid.WGS84,
            fixedFrameTransform,
            planePrimitive.modelMatrix
          );
          if (planePrimitive._boundingSphere !== undefined) {
            Cesium.Matrix4.multiplyByPoint(
              planePrimitive.modelMatrix,
              planePrimitive.boundingSphere.center,
              center
            );
            hpRange.heading = hpRoll.heading + Cesium.Math.toRadians(180.0);
            hpRange.pitch = hpRoll.pitch + Cesium.Math.toRadians(1.0);
            camera.lookAt(center, hpRange);
          }
        });
      });


    }
  }

  const handleCZML = () => {
    if (projectStore.volautoMode) {
      projectStore.setAssignVolautoMode('startCZML', false)
      projectStore.setAssignVolautoMode('pauseCZML', false)
      projectStore.setVolautoMode(false)
      runCZML()
    } else {
      projectStore.setViewMode("Default mode");
      projectStore.setFlyWalkMode(false);
      projectStore.assignVolautoMode({ startCZML: true })
      projectStore.assignVolautoMode({ pauseCZML: false })
      runCZML()
    }
  }

  const handleStartCZML = () => {
    projectStore.setAssignVolautoMode('pauseCZML', false)
    projectStore.setAssignVolautoMode('startCZML', true)
    viewer.clock.shouldAnimate = true;
  }

  const handlePauseCZML = () => {
    projectStore.setAssignVolautoMode('startCZML', false)
    projectStore.setAssignVolautoMode('pauseCZML', true)
    viewer.clock.shouldAnimate = false;
  }

  const checkShowWidgets = (widget) => {
    let show = false
    const widgetsControl = projectStore.widgetsControl
    if (widgetsControl) {
      show = widgetsControl[widget]
    }
    return show
  }

  return (
    <Fragment>
      <ToolbarRightContainer>
        {
          (checkShowWidgets('widget_sunshow') || checkShowWidgets('widget_hdr') || checkShowWidgets('widget_fxaa')) && (
            <ToolbarRightItem>
              <Tooltip title={"Enhancement echniques"} placement="left">
                <AvatarCustom
                  activated={projectStore.currentToolControl === 'enhancement' ? 1 : 0}
                  shape="square"
                  icon={<RiseOutlined />}
                  onClick={() => {
                    if (projectStore.currentToolControl !== 'enhancement') {
                      projectStore.setCurrentToolControl('enhancement')
                    } else {
                      projectStore.setCurrentToolControl('')
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_skybox') && (
            <ToolbarRightItem>
              <Tooltip title={"Sky box"} placement="left">
                <AvatarCustom
                  activated={projectStore.currentToolControl === 'skybox' ? 1 : 0}
                  shape="square"
                  icon={<RadarChartOutlined />}
                  onClick={() => {
                    if (projectStore.currentToolControl !== 'skybox') {
                      projectStore.setCurrentToolControl('skybox')
                    } else {
                      projectStore.setCurrentToolControl('')
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_bloomeffect') && (
            <ToolbarRightItem>
              <Tooltip title={"Bloom effect"} placement="left">
                <AvatarCustom
                  activated={projectStore.currentToolControl === 'bloom' ? 1 : 0}
                  shape="square"
                  icon={<DingdingOutlined />}
                  onClick={() => {
                    if (projectStore.currentToolControl !== 'bloom') {
                      projectStore.setCurrentToolControl('bloom')
                    } else {
                      projectStore.setCurrentToolControl('')
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_fog') && (
            <ToolbarRightItem>
              <Tooltip title={"Fog"} placement="left">
                <AvatarCustom
                  activated={projectStore.currentToolControl === 'fog' ? 1 : 0}
                  shape="square"
                  icon={<AntCloudOutlined />}
                  onClick={() => {
                    if (projectStore.currentToolControl !== 'fog') {
                      projectStore.setCurrentToolControl('fog')
                    } else {
                      projectStore.setCurrentToolControl('')
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_optimize') && (
            <ToolbarRightItem>
              <Tooltip title={"Optimize"} placement="left">
                <AvatarCustom
                  activated={projectStore.currentToolControl === 'optimize' ? 1 : 0}
                  shape="square"
                  icon={<OptimizeIcon />}
                  onClick={() => {
                    if (projectStore.currentToolControl !== 'optimize') {
                      projectStore.setCurrentToolControl('optimize')
                    } else {
                      projectStore.setCurrentToolControl('')
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_measure') && (
            <ToolbarRightItem>
              <Tooltip title={"Measure"} placement="left">
                <AvatarCustom
                  activated={projectStore.viewMode === "Measure distance" ? 1 : 0}
                  shape="square"
                  icon={<MeasureDistance />}
                  onClick={() => {
                    if (projectStore.viewMode === "Measure distance") {
                      projectStore.setViewMode("Default mode");
                    } else {
                      projectStore.setFlyWalkMode(false);
                      projectStore.setViewMode("Measure distance");
                      if (projectStore.volautoMode) {
                        removeCZML("onVolauto");
                        projectStore.setVolautoMode(false)
                      }
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_walk') && (
            <ToolbarRightItem>
              <Tooltip title={"Walk"} placement="left">
                <AvatarCustom
                  activated={projectStore.flyWalkMode ? 1 : 0}
                  shape="square"
                  icon={<FlyWalk />}
                  onClick={() => {
                    if (projectStore.flyWalkMode) {
                      const naviagtionArrowPortal = ReactDOM.createRoot(document.getElementById('navigation-portal'));
                      if (naviagtionArrowPortal) naviagtionArrowPortal.unmount();
                    }
                    projectStore.setFlyWalkMode(!projectStore.flyWalkMode);
                    projectStore.setViewMode("Default mode");
                    if (projectStore.volautoMode) {
                      removeCZML("onVolauto");
                      projectStore.setVolautoMode(false)
                    }
                  }}
                />
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_shadow') && (
            <ToolbarRightItem>
              <Tooltip title={"Shadow"} placement="left">
                <AvatarCustom
                  activated={projectStore.isShowShadowModal ? 1 : 0}
                  shape="square"
                  icon={<Schedule4D />}
                  onClick={() => {
                    projectStore.setShowShadowModal(
                      !projectStore.isShowShadowModal
                    );
                  }}
                ></AvatarCustom>
              </Tooltip>
            </ToolbarRightItem>
          )
        }
        {
          checkShowWidgets('widget_czml') && (
            <ToolbarRightItem>
              <Tooltip title={"Tour"} placement={projectStore.volautoMode ? 'bottomRight' : "left"}>
                <AvatarCustom
                  activated={projectStore.volautoMode ? 1 : 0}
                  shape="square"
                  icon={<Shadows />}
                  onClick={handleCZML}
                ></AvatarCustom>
              </Tooltip>
              {
                projectStore.volautoMode && (
                  <ActionWalkWrapper>
                    <ToolbarRightItem >
                      <Tooltip title={"Start"} placement="bottom">
                        <AvatarCustom
                          activated={projectStore.volautoMode?.startCZML ? 1 : 0}
                          shape="square"
                          icon={<RightCircleOutlined />}
                          onClick={handleStartCZML}
                          size={32}
                        ></AvatarCustom>
                      </Tooltip>
                    </ToolbarRightItem>
                    <ToolbarRightItem >
                      <Tooltip title={"Pause"} placement="bottom">
                        <AvatarCustom
                          activated={projectStore.volautoMode?.pauseCZML ? 1 : 0}
                          shape="square"
                          icon={<PauseCircleOutlined />}
                          onClick={handlePauseCZML}
                          size={32}
                        ></AvatarCustom>
                      </Tooltip>
                    </ToolbarRightItem>
                  </ActionWalkWrapper>
                )
              }
            </ToolbarRightItem>
          )
        }
        {/* <ToolbarRightItem>
          <Tooltip title={"Fly Walk"} placement="left">
            <AvatarCustom
              activated={projectStore.visitemanuelle ? 1 : 0}
              shape="square"
              icon={<Shadows />}
              onClick={() => {
                projectStore.setvisitemanuelle(!projectStore.visitemanuelle)
                visitemanuelleMode()
              }
              }
            ></AvatarCustom>
          </Tooltip>
        </ToolbarRightItem> */}
      </ToolbarRightContainer>
    </Fragment>
  );
};

export default inject("projectStore")(observer(ToolbarRight));
