import { Form, Popconfirm, Upload, message } from 'antd'
import React, { Fragment } from 'react'
import { PinImageWrapper } from './styled'
import { DeleteTwoTone, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { isImage, uploadImage } from '../../../utils/projectLib';
import { inject, observer } from 'mobx-react';
import { apiUrl } from '../../../utils/config';

function PinImage({authStore, adminStore, imageUrl, setImageUrl, propertiesName}) {

    const uploadButton = () => {
        return (
        <Fragment>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </Fragment>
        )
    }

    const handleUploadPinImage = async file => {
        const formData = new FormData()
        formData.append('file', file, file.name)
        
        await uploadImage(formData, authStore.token).then(res => {
            if(res?.data?.filename) setImageUrl(isImage(res?.data?.filename) && res?.data?.filename || null)
        }).catch(err => adminStore.openNotification(
            "bottomRight",
            "error",
            'Upload image failed!'
          ))
        return false
    }

    const clearProjectImage = () =>{
        setImageUrl(null)
    }

    const DeleteProjectImage = () => {
        return (
            <Popconfirm
                okText={'Delete'}
                cancelText={'Cancel'}
                title={'Are you sure'}
                okType='danger'
                zIndex={9999}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => clearProjectImage()}
            >
             <DeleteTwoTone className='delete-icon' style={{fontSize: 16, backgroundColor: 'whitesmoke', width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10}}/>
            </Popconfirm>
        )
    }

  return (
    <PinImageWrapper>
        <Form.Item
            label="Image"
            name={[`${propertiesName}`]}
            valuePropName="file">
            <Upload
                accept='image/*'
                showUploadList={false}
                name="avatar"
                className={`upload-image`}
                beforeUpload={file => handleUploadPinImage(file)}
                listType="picture-card"
            >
                {
                    imageUrl 
                    ? <img crossOrigin="anonymous" src={`${apiUrl}/files/${imageUrl}`} alt="" className='input-upload-image-project' />
                    : uploadButton()
                }
            </Upload>
            {imageUrl && <DeleteProjectImage />}
        </Form.Item>
    </PinImageWrapper>
  )
}

export default inject("adminStore", "authStore")(observer(PinImage));