import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SaveOutlined
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table
} from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import GroupItem from "../../AdminDashboard/GroupItem";
import { ContentWrapper, ToolbarButton, ToolbarWrapper, MainContain, TableContain, ContainRelation, WidgetControl } from "../../AdminDashboard/style";
import ModalAddPin from "../ModalAddPin";
import UploadImage from "../../../components/elements/UploadImage/UploadImage";
import { isImage } from "../../../utils/projectLib";
const { TextArea } = Input;
const EditSubCategory = ({ adminStore, authStore }) => {
  const [form] = Form.useForm();
  const [addPointOfInterestForm] = Form.useForm();
  const [addPinForm] = Form.useForm();
  const navigate = useNavigate();
  const { subcategoryId } = useParams();

  const [categories, setCategories] = useState([]);
  const [ionIds, setIonIds] = useState([]);
  const [pointofinteresets, setPointofinteresets] = useState([]);
  const [pins, setPins] = useState([]);
  const [allpointofinteresets, setAllPointofinteresets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [subFolderDetail, setSubFolderDetail] = useState(null);
  const [inputIon, setInputIon] = useState(true);
  const [inputIonModal, setInputIonModal] = useState(true);
  const [subFolderImageUrl, setSubFolderImageUrl] = useState(null)
  const [sub_subFolderImageUrl, setSub_subFolderImageUrl] = useState(null)

  window.onscroll = () =>
    adminStore.onscrollSticky(window, document, "toolbar-sticky");

  const page = adminStore.currentPage;
  const limit = adminStore.currentLimit;
  const search = adminStore.searchInput;
  const { Option } = Select;

  const backTo = () => {
    return navigate(`/admin/subfolders?page=${page}&limit=${limit}${search && search !== '' ? '&search=' + search : ''}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      await adminStore.getAllPointOfInterest(1, 1000).then(res => {
        setAllPointofinteresets(res.docs)
      })
      await adminStore.getAllCategories(1, 100).then(res => {
        setCategories(res.docs);
      })
      adminStore.getIonIds().then(res => {
        setIonIds(res)
      })
      if (subcategoryId === "new") {
        form.resetFields();
      } else {
        await adminStore.getSubCategoryDetail(subcategoryId).then((response) => {
          setSubFolderDetail(response);
          setPins(response?.pin || [])
          setPointofinteresets(response.subcategorychecklists || [])
          form.setFieldsValue({
            title: response?.title,
            desc: response?.desc,
            order: response?.order || 0,
            ionAccess: response?.ionAccess,
            type: response?.type,
            height: response?.height,
            categoryId: response?.categoryId,
            fillcolor: response?.fill?.color,
            fillopacity: response?.fill?.opacity,
            strokewidth: response?.stroke?.width,
            strokecolor: response?.stroke?.color,
            strokeopacity: response?.stroke?.opacity
          });
          if (response.orientation) {
            const orientation = response.orientation;
            form.setFieldsValue({
              orientationheading: orientation?.heading || 0,
              orientationpitch: orientation?.pitch || 0,
              orientationroll: orientation?.roll || 0,
              orientationlat: orientation?.lat || 0,
              orientationlon: orientation?.lon || 0,
              orientationheight: orientation?.height || 0,
            });
          }
          if (response.properties) {
            const properties = response.properties;
            setSubFolderImageUrl(isImage(properties?.image) && properties?.image || null)
            form.setFieldsValue({
              propertiestitle: properties?.title || "",
              propertiesdescription: properties?.description || "",
              propertiesimage: properties?.image || ""
            });
          }
          if (response?.type === 'place') {
            setInputIon(false);
          }
        });
      }
    };
    fetchData();
    adminStore.setSelectedKeyMenu("subfolders?page=1&limit=10");
    return () => {
      form.resetFields();
      setCategories([]);
      setIonIds([])
      setPointofinteresets([])
      setAllPointofinteresets([])
      setIsModalOpen(false)
      setChecked(true)
      setPins([]);
      setSubFolderImageUrl(null)
      setSub_subFolderImageUrl(null)
    };
  }, [subcategoryId]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      const payload = {
        title: values?.title || "",
        desc: values?.desc || "",
        order: values?.order < 0 ? 0 : values?.order || 0,
        ionAccess: values?.ionAccess || null,
        type: values?.type,
        height: values?.height,
        categoryId: values?.categoryId || null,
        orientation: {
          heading: values?.orientationheading || 0,
          pitch: values?.orientationpitch || 0,
          roll: values?.orientationroll || 0,
          lat: values?.orientationlat || 0,
          lon: values?.orientationlon || 0,
          height: values?.orientationheight || 0
        },
        properties: {
          title: values?.propertiestitle || "",
          description: values?.propertiesdescription || "",
          image: subFolderImageUrl || ""
        },
        fill: {
          color: values?.fillcolor,
          opacity: values?.fillopacity
        },
        stroke: {
          width: values?.strokewidth,
          color: values?.strokecolor,
          opacity: values?.strokeopacity
        }
      };
      if (subcategoryId === "new") {
        return createSubCategory(payload);
      } else {
        return updateSubCategory(subcategoryId, payload);
      }
    });
  }

  const createSubCategory = async (payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .createSubCategory(payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        backTo()
        adminStore.openNotification('bottomRight', 'success', 'Create new sub-category successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const updateSubCategory = async (id, payload) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updateSubCategory(id, payload)
      .then((response) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'success', 'Update sub-category successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  };

  const deleteSubCategory = async () => {
    if (subcategoryId && subcategoryId !== "new") {
      adminStore.setLoadingProgress(true);
      await adminStore
        .deleteSubCategory(subcategoryId)
        .then((response) => {
          adminStore.openNotification('bottomRight', 'success', 'Delete sub-category successfully!');
          backTo()
        })
        .catch((err) => {
          adminStore.setLoadingProgress(false);
          adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
        });
    } else {
      adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
    }
  };

  const handleAddPointOfInterest = () => {
    addPointOfInterestForm.validateFields().then(async values => {
      if (values.useExisting) {
        adminStore.setLoadingProgress(true);
        let record = allpointofinteresets.find(c => c.id === values.subsubfolder)
        await adminStore
          .updatePointOfInterest(values.subsubfolder,
            {
              subCategoryId: subcategoryId,
              title: record?.title,
              order: record?.order || 0,
              ionAccess: record?.ionAccess || null,
              type: record?.type,
              height: (record?.height || 0),
              orientation: record.orientation || {},
              properties: record.properties || {},
              stroke: record.stroke || {},
              fill: record.fill || {}
            }
          )
          .then((response) => {
            setPointofinteresets(pointofinteresets.filter(c => c.id !== record.id))
            setPointofinteresetDetail(response.id, subcategoryId)
            let isExist = pointofinteresets.find(c => c.id === values.subsubfolder)
            if (!isExist) {
              setPointofinteresets(pointofinteresets => [
                ...pointofinteresets,
                response
              ])
            }
            adminStore.setLoadingProgress(false);
            adminStore.openNotification('bottomRight', 'success', 'Add sub-subfolder to sub-folder successfully!');
            handleCancel()
          })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
          })
      } else {
        let payload = {
          subCategoryId: subcategoryId,
          title: values?.title,
          order: values?.order < 0 ? 0 : values?.order || 0,
          ionAccess: values?.ionAccess || null,
          type: values?.type,
          height: parseFloat(values?.height || 0),
          orientation: {
            heading: parseFloat(values?.orientationheading || 0),
            pitch: parseFloat(values?.orientationpitch || 0),
            roll: parseFloat(values?.orientationroll || 0),
            lat: parseFloat(values?.orientationlat || 0),
            lon: parseFloat(values?.orientationlon || 0),
            height: parseFloat(values?.orientationheight || 0)
          },
          properties: {
            title: values?.propertiesTitle,
            description: values?.propertiesDescription,
            image: sub_subFolderImageUrl || ''
          },
          stroke: {
            width: values?.strokewidth,
            color: values?.strokecolor,
            opacity: values?.strokeopacity
          },
          fill: {
            color: values?.fillcolor,
            opacity: values?.fillopacity
          }
        };
        adminStore.setLoadingProgress(true);
        await adminStore.createNewPointOfInterest(payload).then((res) => {
          adminStore.setLoadingProgress(false);
          setPointofinteresets(pointofinteresets => [
            ...pointofinteresets,
            res
          ])
          adminStore.openNotification(
            "bottomRight",
            "success",
            "Created a new sub-subfolder successfully!"
          );
          handleCancel()
        })
          .catch((err) => {
            adminStore.setLoadingProgress(false);
            adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
          });
      }
    })
  }

  const setPointofinteresetDetail = (id, value) => {
    let index = allpointofinteresets.findIndex(c => c.id === id)
    if (index > -1) {
      let c = [...allpointofinteresets]
      c[index].subCategoryId = value
      setAllPointofinteresets(c)
    }
  }

  const confirmRemovePointOfInteresets = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .updatePointOfInterest(record.id,
        {
          subCategoryId: null,
          title: record?.title,
          heading: (record?.heading || 0),
          pitch: (record?.pitch || 0),
          roll: (record?.roll || 0),
          lat: (record?.lat || 0),
          lon: (record?.lon || 0),
          height: (record?.height || 0),
          properties: record.properties || {}
        }
      )
      .then((response) => {
        setPointofinteresets(pointofinteresets.filter(c => c.id !== record.id))
        setPointofinteresetDetail(record.id, null)
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'success', 'Remove sub-subfolder from sub-folder successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      })
  }

  const handleOpenAddPointOfInteresetModal = () => {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    addPointOfInterestForm.resetFields()
    setChecked(true)
    setInputIonModal(true);
    setSub_subFolderImageUrl(null)
  };

  const handleOpenAddPinModal = () => {
    adminStore.setOpenPinModal(true);
  }

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked)
  };

  const titleOnClick = () => {
    localStorage.setItem("subFolderDetail", JSON.stringify(subFolderDetail))
  }

  const columnpointofinteresets = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a href={`/admin/sub-subfolders/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 80,
      align: "center",
      render: (record, obj) => {
        return <span> {record ? record : 0} </span>
      }
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmRemovePointOfInteresets(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const confirmDeletePin = async (record) => {
    adminStore.setLoadingProgress(true);
    await adminStore
      .deletePin(record.id)
      .then((response) => {
        setPins(pins.filter(pin => pin.id !== record.id));
        adminStore.openNotification('bottomRight', 'success', 'Delete pin successfully!');
      })
      .catch((err) => {
        adminStore.setLoadingProgress(false);
        adminStore.openNotification('bottomRight', 'error', 'Something went wrong!');
      });
  }

  const columnpins = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <a className="relation-label" href={`/admin/pins/${record.id}`} target='_blank' onClick={titleOnClick}>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (record) => (
        <div className="action-show">
          <Popconfirm
            placement="topLeft"
            title={'Are you sure to remove this record?'}
            onConfirm={() => confirmDeletePin(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const Relation = ({ title, children, action, icon, handleClick }) => {
    return (
      <div className="relation">
        <Row style={{ paddingBottom: "5px" }} justify="space-between">
          <div>{title}</div>
          {
            action && subcategoryId !== 'new' && (
              <Button onClick={() => handleClick()}>
                {icon}
                {action}
              </Button>
            )
          }
        </Row>
        {children}
      </div>
    );
  };

  const onSelectChange = (value) => {
    if (value === 'place') {
      setInputIon(false);
    } else {
      setInputIon(true);
    }
  }

  const onSelectChangeModal = (value) => {
    if (value === 'place') {
      setInputIonModal(false);
    } else {
      setInputIonModal(true);
    }
  }

  return (
    <AdminDashboard>
      <ContentWrapper>
        <div id="toolbar-sticky">
          <ToolbarWrapper className="sticky-children">
            <Row justify="end">
              <ToolbarButton
                className="action-btn"
                icon={<CloseCircleOutlined />}
                onClick={backTo}
              >
                CANCEL
              </ToolbarButton>
              {subcategoryId !== "new" && (
                <ToolbarButton
                  type="danger"
                  className="action-btn"
                  icon={<DeleteOutlined />}
                  onClick={deleteSubCategory}
                >
                  DELETE
                </ToolbarButton>
              )}
              <ToolbarButton
                type="primary"
                className="action-btn"
                onClick={onFinish}
                icon={
                  subcategoryId === "new" ? (
                    <PlusCircleOutlined />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {subcategoryId === "new" ? "CREATE" : "SAVE"}
              </ToolbarButton>
            </Row>
          </ToolbarWrapper>
        </div>
        <MainContain>
          <TableContain>
            <Form
              name="edit-sub-category"
              form={form}
              layout="vertical"
              labelAlign="left"
            >
              <Row>
                <Col span={12}>
                  <Row >
                    <Col span={24}>
                      <Form.Item name={"title"} label="Title"
                        rules={[
                          {
                            required: true,
                            message: "Please input title",
                          },
                        ]}
                      >
                        <Input className="form-input" placeholder="Please input title" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={"desc"} label="description"
                        rules={[
                          {
                            required: false,
                            message: "Please input description",
                          },
                        ]}
                      >
                        <TextArea rows={4} className="form-input" placeholder="Please input description" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={["order"]}
                        label="Order"
                        rules={[
                          {
                            required: false
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          min={0}
                          className="form-input"
                          placeholder="Please input order number"
                        />
                      </Form.Item>
                    </Col>
                    <Row>
                      <Col span={24}>
                        <Form.Item name={'type'} label="Type"
                          rules={[
                            {
                              required: true,
                              message: "Please input ionAccess",
                            },
                          ]}
                        >
                          <Select
                            options={[
                              { value: 'ion', label: 'ION 3DTiles' },
                              { value: 'geojson', label: 'ION Geojson' },
                              { value: 'imagery', label: 'ION Imagery' },
                              { value: 'czml', label: 'ION Czml' },
                              { value: 'place', label: 'Place' }
                            ]}
                            onChange={onSelectChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {
                      inputIon && (
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label={"ion id"}
                              name="ionAccess"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    "Please select a ionid",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={
                                  "Please select a ionid"
                                }
                                allowClear={true}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {ionIds.map((v, i) => {
                                  return (
                                    <Option key={v.id} value={v.id}>
                                      {`${v.name} (${v.id})`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    }
                    <Col span={24}>
                      <Form.Item
                        label={"folder"}
                        name="categoryId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select a category",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder={
                            "Please select a category"
                          }
                          allowClear={true}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {categories.map((v, i) => {
                            return (
                              <Option key={v.id} value={v.id}>
                                {v.title}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item name={"height"} label="Height">
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <GroupItem title={"orientation"}>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"orientationlat"} label="Lat">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={["orientationlon"]} label="Lon">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"orientationheight"} label="Height">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"orientationheading"} label="heading">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={["orientationpitch"]} label="pitch">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item name={"orientationroll"} label="roll">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </GroupItem>
                    <GroupItem title={"properties"}>
                      <Col span={24}>
                        <Form.Item name={"propertiestitle"} label="title">
                          <Input placeholder="Please input properties title" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["propertiesdescription"]} label="description">
                          <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <UploadImage imageUrl={subFolderImageUrl} setImageUrl={setSubFolderImageUrl} propertiesName={'propertiesimage'}/>
                      </Col>
                    </GroupItem>
                    <GroupItem title={"Stroke"}>
                      <Col span={12}>
                        <Form.Item name={"strokewidth"} label="Witdh">
                          <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name={["strokecolor"]} label="color">
                          <input
                            style={{ width: '150px', height: '150px' }}
                            type="color"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name={"strokeopacity"} label="Opacity">
                          <InputNumber max={1} min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </GroupItem>
                    <GroupItem title={"Fill"}>
                      <Col span={24}>
                        <Form.Item name={"fillcolor"} label="Color">
                          <input
                            style={{ width: '150px', height: '150px' }}
                            type="color"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name={["fillopacity"]} label="Opacity">
                          <InputNumber max={1} min={0} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </GroupItem>
                  </Row>
                </Col>
                <Col span={12}>
                  <ContainRelation>
                    <Row>
                      <WidgetControl span={24}>
                        <Relation
                          handleClick={handleOpenAddPointOfInteresetModal}
                          title={"SUB-SUBFOLDERS"}
                          action={"Add sub-subfolder"}
                          icon={<PlusOutlined />}
                        >
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            columns={columnpointofinteresets}
                            dataSource={pointofinteresets.sort((a, b) => a.order === b.order ? a.createdAt.localeCompare(b.createdAt) : a.order - b.order)}
                            scroll={{ y: pointofinteresets.length > 5 ? 213 : null }}
                          />
                        </Relation>
                      </WidgetControl>
                      <WidgetControl span={24}>
                        <Relation
                          handleClick={handleOpenAddPinModal}
                          title={"PIN"}
                          action={"Add pin"}
                          icon={<PlusOutlined />}
                        >
                          <Table
                            rowKey={(record) => record.id}
                            bordered
                            pagination={false}
                            columns={columnpins}
                            dataSource={pins}
                            scroll={{ y: pins.length > 5 ? 213 : null }}
                          />
                        </Relation>
                      </WidgetControl>
                    </Row>
                  </ContainRelation>
                </Col>
              </Row>
            </Form>
            <Modal
              title="Add sub-subfolder into sub-folder"
              zIndex={9999}
              open={isModalOpen}
              onOk={handleAddPointOfInterest}
              onCancel={handleCancel}
              maskClosable={false}
              className='modal-add-folder'
            >
              <Form
                name="add-folder-toproject"
                form={addPointOfInterestForm}
                layout="vertical"
                labelAlign="left"
              >
                <Form.Item
                  name="useExisting"
                  valuePropName="checked"
                  initialValue={true}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox checked={checked} onChange={onChangeCheckbox}>Use existing sub-subfolder</Checkbox>
                </Form.Item>
                {
                  checked && (
                    <Form.Item
                      label={"Sub-subfolder"}
                      name="subsubfolder"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select sub-subfolder",
                        },
                      ]}
                    >
                      <Select
                        placeholder={
                          "Please select sub-subfolder"
                        }
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          allpointofinteresets.filter(c => !c.subCategoryId)
                            .map((v, i) => {
                              return (
                                <Option key={v.id} value={v.id}>
                                  {v.title}
                                </Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  )
                }
                {
                  !checked && (
                    <>
                      <Form.Item
                        name={["title"]}
                        label="Title"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          className="form-input"
                          placeholder="Please input title"
                        />
                      </Form.Item>
                      <Form.Item name={'type'} label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Please input ionAccess",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            { value: 'ion', label: 'ION 3DTiles' },
                            { value: 'geojson', label: 'ION Geojson' },
                            { value: 'imagery', label: 'ION Imagery' },
                            { value: 'czml', label: 'ION Czml' },
                            { value: 'place', label: 'Place' }
                          ]}
                          onChange={onSelectChangeModal}
                        />
                      </Form.Item>
                      {
                        inputIonModal && (
                          <Form.Item label={"Ion ID"} name="ionAccess"
                            rules={[
                              {
                                required: false,
                                message: "Please select a ionid"
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder={"Please select a ionid"}
                              allowClear={true}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {ionIds.map((v, i) => {
                                return (
                                  <Option key={v.id} value={v.id}>
                                    {`${v.name} (${v.id})`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )
                      }
                      <Form.Item
                        name={["order"]}
                        label="Order"
                        rules={[
                          {
                            required: false
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          min={0}
                          className="form-input"
                          placeholder="Please input order number"
                        />
                      </Form.Item>
                      <Form.Item name={["height"]} label="Height" rules={[
                        {
                          required: false
                        }
                      ]}>
                        <Input
                          type="number"
                          className="form-input"
                          placeholder="Please input height"
                        />
                      </Form.Item>
                      <GroupItem title={"orientation"}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationlat"} label="Lat">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationlon"]} label="Lon">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheight"} label="Height">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationheading"} label="Heading">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={["orientationpitch"]} label="Pitch">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                          <Form.Item name={"orientationroll"} label="Roll">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"properties"}>
                        <Col span={24}>
                          <Form.Item
                            name={["propertiesTitle"]}
                            label="Title"
                          >
                            <Input
                              className="form-input"
                              placeholder="Please input properties title"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name={["propertiesDescription"]}
                            label="Description"
                          >
                            <TextArea rows={4} className="form-input" placeholder="Please input properties description" />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <UploadImage imageUrl={sub_subFolderImageUrl} setImageUrl={setSub_subFolderImageUrl} propertiesName={'propertiesImage'}/>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"Stroke"}>
                        <Col span={24}>
                          <Form.Item name={"strokewidth"} label="Witdh">
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={["strokecolor"]} label="color">
                            <input
                              style={{ width: '150px', height: '150px' }}
                              type="color"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={"strokeopacity"} label="Opacity">
                            <InputNumber max={1} min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                      <GroupItem title={"Fill"}>
                        <Col span={24}>
                          <Form.Item name={"fillcolor"} label="Color">
                            <input
                              style={{ width: '150px', height: '150px' }}
                              type="color"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item name={["fillopacity"]} label="Opacity">
                            <InputNumber max={1} min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </GroupItem>
                    </>
                  )
                }
              </Form>
            </Modal>

            {/* Modal add pin */}
            <ModalAddPin setPinsSubFolder={setPins} subFolderId={subcategoryId} />
          </TableContain>
        </MainContain>
      </ContentWrapper>
    </AdminDashboard>
  );
};

export default inject(
  "userStore",
  "adminStore",
  "authStore"
)(observer(EditSubCategory));
