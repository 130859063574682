import { Col } from 'antd'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgb(15 34 58 / 12%); */
    box-shadow:  rgb(40 42 49 / 16%) 0px 2px 1px;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    left:${props => props.collapsed ? `80px` : `200px`};
    transition : left 0.27s;
    @media only screen and (max-width: 768px) {
        left: 0;
    }
`

export const AccountWrapper = styled(Col)`
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const MenuIconWrapper = styled(Col)`
    width: 50px;
    display : flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        border-right: solid 1px rgb(221, 221, 221);
        width: 80px;
        height: 70px;
        margin-left: -9.5px !important;
    }
`