import {
     Button, Col, Input, Popconfirm, Row, Table, Tooltip
} from "antd";
import { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard/AdminDashboard";
import {
     DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined
} from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { ButtonTable, ToolbarWrapper, TableContain, MainContain, ContentWrapper } from "../../AdminDashboard/style";
import debounce from 'lodash/debounce';
import moment from 'moment';
// import { useMediaQuery } from "react-responsive";

const Categories = ({ adminStore, authStore }) => {
     // const isMobile = useMediaQuery({ query: "(max-width: 375px)" });
     const navigate = useNavigate();

     const [selectedRowKeys, setSelectedRowKeys] = useState([]);
     const [totalDocs, setTotalDocs] = useState(1);
     const [searchParams, setSearchParams] = useSearchParams();

     window.onscroll = () => adminStore.onscrollSticky(window, document, "toolbar-sticky");

     const onSelectChange = selectedRowKeys => {
          setSelectedRowKeys(selectedRowKeys);
     }

     const handleChangePagination = (current, pageSize) => {
          adminStore.setCurrentLimit(pageSize);
          adminStore.setCurrentPage(current);
          if (paramSearch && paramSearch !== "") {
               setSearchParams({ page: current, limit: pageSize, search: paramSearch });
          } else {
               setSearchParams({ page: current, limit: pageSize });
          }
     }

     const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 1;
     const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit")) : 10;
     const paramSearch = searchParams.get("search") ? searchParams.get("search") : "";

     adminStore.setSearchInput(paramSearch);

     const searchInputChange = (event) => {
          debounceCalculate(event.target.value);
     }

     const debounceCalculate = debounce((value) => {
          handleSearchInput(value);
     }, 1000)

     const handleSearchInput = (e) => {
          let search = e && e !== '' ? e.trim() : '';
          if (search && search !== "") {
               setSearchParams({ page: 1, limit: limit, search: search });
          } else {
               setSearchParams({ page: 1, limit: limit });
          }
     }

     const rowSelection = {
          selectedRowKeys,
          onChange: onSelectChange,
     }

     const hasSelected = selectedRowKeys.length > 0;

     useEffect(() => {
          adminStore.setSelectedKeyMenu(`folders?page=1&limit=10`);
          const fetchCategoriesData = async () => {
               await adminStore.getAllCategories(page, limit, paramSearch)
                    .then(res => {
                         if (res.docs) {
                              adminStore.setCategoriesData(res.docs);
                              setTotalDocs(res.totalDocs);
                         }
                    })
                    .catch(err => {
                         adminStore.setLoadingProgress(false);
                    })
          }
          fetchCategoriesData();
     }, [adminStore, limit, page, paramSearch])

     const deleteCategory = async (id) => {
          await adminStore.deleteCategory(id).then((res) => {
               const data = adminStore.categoriesData.filter((category) => category.id !== res.id);
               adminStore.setCategoriesData(data);
               adminStore.openNotification('bottomRight', 'success', 'Deleted this folder successfully!')
          })
               .catch(err => adminStore.openNotification('bottomRight', 'error', 'Something went wrong!'));
     }

     const handleDeleteMultiple = async (record) => {
          adminStore.setLoadingProgress(true);
          await adminStore.deleteMultipleCategories(record).then(res => {
               const _categories = [...adminStore.categoriesData];
               for (let i = 0; i < record.length; i++) {
                    const index = _categories.findIndex((ind) => ind.id === record[i]);
                    if (index >= 0) {
                         _categories.splice(index, 1);
                    }
               }
               setSelectedRowKeys([])
               adminStore.setCategoriesData(_categories);
               adminStore.openNotification('bottomRight', 'success', 'Deleted these folders successfully!')
          })
               .catch(err => adminStore.openNotification('bottomRight', 'error', 'Something went wrong!'));
     }

     const columns = [
          {
               title: 'Title',
               dataIndex: 'title',
               key: 'title',
               sortDirections: ['descend', 'ascend'],
               sorter: (a, b) => a.title.localeCompare(b.title),
               render: (record, obj) => {
                    return <span>
                         <Link to={`/admin/folders/${obj.id}`} style={{ color: "#000" }}>
                              {record}
                         </Link>
                    </span>
               },
          },
          {
               title: "Project",
               key: 'project',
               dataIndex: 'project',
               sortDirections: ['descend', 'ascend'],
               sorter: (a, b) => a && b && a?.project && b.project && a?.project?.name.localeCompare(b?.project?.name),
               render: (record, obj) => {
                    return <span> {record ? record.name : ''} </span>
               },
          },
          {
               title: "Created At",
               dataIndex: 'createdAt',
               key: 'createdAt',
               defaultSortOrder: 'descend',
               sortDirections: ['descend', 'ascend'],
               sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
               render: (record, obj) => {
                    return <span> {moment(record).format('MMMM Do YYYY, h:mm:ss A')} </span>
               },
          },
          {
               title: 'Action',
               dataIndex: 'action',
               fixed: "right",
               width: 100,
               key: 'action',
               align: "center",
               render: (record, obj) => {
                    return (
                         <Row type="flex" justify="center" key="action">
                              <Tooltip placement="topLeft" title={'Edit'}>
                                   <Link to={`/admin/folders/${obj.id}`}>
                                        <ButtonTable>
                                             <EditOutlined />
                                        </ButtonTable>
                                   </Link>
                              </Tooltip>
                              <Tooltip placement="topLeft" title={'Delete'}>
                                   <Popconfirm
                                   placement="left"
                                        title={"Are you sure delete this record?"}
                                        onConfirm={() => deleteCategory(obj.id)}
                                        okText="Yes"
                                        cancelText="No"
                                   >
                                        <ButtonTable>
                                             <DeleteOutlined />
                                        </ButtonTable>
                                   </Popconfirm>
                              </Tooltip>
                         </Row>
                    )
               }
          }
     ]

     const onBtnAddClick = () => {
          navigate("new");
     }

     return (
          <AdminDashboard>
               <ContentWrapper>
                    <div id="toolbar-sticky">
                         <ToolbarWrapper className="sticky-children">
                              <Col xs={24} sm={24} md={12} lg={12}>
                                   <Input
                                        className="action-search"
                                        placeholder=" Search..."
                                        prefix={<SearchOutlined />}
                                        onChange={searchInputChange}
                                        defaultValue={paramSearch}
                                        allowClear={true}
                                   />
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "end" }}>
                                   {
                                        hasSelected &&
                                        <Button
                                             type="danger"
                                             className="action-btn"
                                             icon={<DeleteOutlined />}
                                             style={{ paddingLeft: 17, paddingRight: 17 }}
                                             onClick={() => handleDeleteMultiple(selectedRowKeys)}
                                        >
                                             DELETE
                                        </Button>
                                   }
                                   <Button
                                        type="primary"
                                        className="action-btn"
                                        icon={<PlusCircleOutlined />}
                                        style={{ marginLeft: 2 }}
                                        onClick={onBtnAddClick}
                                   >
                                        ADD NEW
                                   </Button>
                              </Col>
                         </ToolbarWrapper>
                    </div>
                    <MainContain>
                         <TableContain>
                              <Col span={24}>
                                   <Table
                                        bordered
                                        size="default"
                                        columns={columns}
                                        dataSource={adminStore.categoriesData || []}
                                        rowSelection={rowSelection}
                                        rowKey={record => record.id}
                                        scroll={{ x: 1000 }}
                                        pagination={{
                                             defaultPageSize: limit,
                                             showSizeChanger: true,
                                             current: page,
                                             pageSize: limit,
                                             total: totalDocs || 1,
                                             pageSizeOptions: ["10", "20", "50", "100"],
                                             locale: { items_per_page: "" },
                                             onChange: (current, pageSize) => {
                                                  handleChangePagination(current, pageSize)
                                             }
                                        }}
                                        locale={{ emptyText: "No data" }}
                                        className="border-space"
                                   />
                              </Col>
                         </TableContain>
                    </MainContain>
               </ContentWrapper>
          </AdminDashboard>
     );
};

export default inject('authStore', 'adminStore')(observer(Categories));